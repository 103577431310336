import { useTranslation } from 'next-i18next';
import React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs from 'dayjs';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { formatDate } from 'stars-utils/lib/helpers/dates/formatDate';
import { useAdvertiserAmountProps } from 'components/Dashboard/components/AdvertiserDashboardOverview/hooks/useAdvertiserAmountProps';
import { MonthChartPreview } from 'components/Dashboard/components/MonthChartPreview';
import {
  CURRENT_DATE_RANGE,
  DATE_PREVIEW_CHART_LABEL_FORMAT,
} from 'components/Dashboard/constants/dates';
import { isTodayFirstDayOfMonth } from 'components/Dashboard/helpers/isTodayFirstDayOfMonth';
import { useAdvertiserChartData } from 'components/Dashboard/hooks/useAdvertiserChartData';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

interface ISpentThisMonthProps {
  title?: string;
}

export const SpentThisMonth: React.FC<ISpentThisMonthProps> = ({
  title,
}) => {
  const { t } = useTranslation('common');
  const monthSpending = useAdvertiserAmountProps({
    dateRange: CURRENT_DATE_RANGE,
    metric: Metric.AMOUNT,
  });

  const shouldShowChart = !isTodayFirstDayOfMonth();

  const { data: chartData } = useAdvertiserChartData({
    dateFrom: CURRENT_DATE_RANGE[0],
    dateTo: dayjs(),
    mapper: (date, metric): IPreviewChartRecord => ({
      date: formatDate(date, DATE_PREVIEW_CHART_LABEL_FORMAT),
      value: metric?.[Metric.AMOUNT] || 0,
    }),
  });

  return (
    <AccountingWidget
      icon={<DateRangeIcon color="primary" />}
      title={title || t('Spent this month')}
      amountProps={{
        value: monthSpending.value,
        unit: 'USD',
        isLoading: monthSpending.isLoading,
      }}
      content={
        shouldShowChart && <MonthChartPreview data={chartData} />
      }
    />
  );
};
