import { Dayjs } from 'dayjs';
import { usePublisherAdvancedCustomReportByDay } from 'stars-hooks/lib/hooks/api/statistics/usePublisherAdvancedCustomReportByDay';
import {
  IChartDataMapper,
  createChartData,
} from 'components/Dashboard/helpers/createChartData';
import { useDashboardUserId } from 'components/Dashboard/hooks/useDashboardUserId';

interface IUsePublisherAdvancedChartDataParams<T> {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  mapper: IChartDataMapper<T>;
}

interface IUsePublisherAdvancedChartDataReturn<T> {
  data?: T[];
  isError: boolean;
}

export const usePublisherAdvancedChartData = <T>(
  params: IUsePublisherAdvancedChartDataParams<T>,
): IUsePublisherAdvancedChartDataReturn<T> => {
  const { dateFrom, dateTo } = params;

  const userId = useDashboardUserId();
  const { data: metricsData, error } =
    usePublisherAdvancedCustomReportByDay(
      {
        dateFrom: dateFrom.toDate(),
        dateTo: dateTo.toDate(),
        userId,
      },
      { shouldShowError: true },
    );

  const isLoading = !metricsData && !error;

  return {
    data: isLoading
      ? undefined
      : createChartData({
          ...params,
          metricsData,
        }),
    isError: Boolean(error),
  };
};
