import { useTranslation } from 'next-i18next';
import React from 'react';
import { useTheme } from '@mui/material';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelProps,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { formatDate } from 'stars-utils/lib/helpers/dates/formatDate';
import { getShortNumber } from 'stars-utils/lib/helpers/numbers/getShortNumber';
import { DashboardChartLoadingAnimation } from 'components/Dashboard/components/DashboardChart/components/DashboardChartLoadingAnimation';
import { DashboardChartPlaceholder } from 'components/Dashboard/components/DashboardChart/components/DashboardChartPlaceholder';
import { DashboardChartTooltip } from 'components/Dashboard/components/DashboardChart/components/DashboardChartTooltip';
import { useDashboardContext } from 'components/Dashboard/components/DashboardProvider';
import { useChartColors } from 'components/Dashboard/hooks/useChartColors';
import { useMetricLabelByType } from 'components/Dashboard/hooks/useMetricLabelByType';
import { IChartData } from 'components/Dashboard/types/chart';

const LABEL_MARGIN = 4;

interface IDashboardChartProps {
  chartData: IChartData;
}

export const DashboardChart: React.FC<IDashboardChartProps> = ({
  chartData,
}) => {
  const { t } = useTranslation('common');
  const { palette, typography } = useTheme();
  const { barColor, lineColor } = useChartColors();
  const labelByMetric = useMetricLabelByType();
  const { data, isError } = chartData;

  const { barMetric, lineMetric } = useDashboardContext();

  const commonAxisProps: XAxisProps & YAxisProps = {
    strokeWidth: 0,
    stroke: palette.grey[600],
    fontSize: '0.7rem',
    fontFamily: typography.fontFamily,
  };

  const commonYAxisProps: YAxisProps = {
    ...commonAxisProps,
    tickCount: 24,
    tickFormatter: (barValue): string => getShortNumber(barValue),
  };

  const commonLabelProps: LabelProps = {
    fill: palette.grey[600],
    fontSize: '0.75rem',
    fontFamily: typography.fontFamily,
  };

  if (isError) {
    return (
      <DashboardChartPlaceholder text={t('Something went wrong')} />
    );
  }

  if (!data) {
    return <DashboardChartLoadingAnimation />;
  }

  if (!data.length) {
    return <DashboardChartPlaceholder />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      {/* eslint-disable-next-line react/forbid-component-props */}
      <ComposedChart style={{ marginTop: 12 }} data={data}>
        <CartesianGrid vertical={false} stroke={palette.grey[200]} />
        <XAxis
          {...commonAxisProps}
          dataKey="date"
          tickFormatter={(value): string => formatDate(value)}
          dy={LABEL_MARGIN}
        />
        <YAxis
          {...commonYAxisProps}
          dataKey="barValue"
          yAxisId="left"
          orientation="left"
          dx={-LABEL_MARGIN}
        >
          <Label
            {...commonLabelProps}
            value={labelByMetric[barMetric]}
            angle={-90}
            position="insideLeft"
            dx={-4}
          />
        </YAxis>
        <YAxis
          {...commonYAxisProps}
          dataKey="lineValue"
          yAxisId="right"
          orientation="right"
          dx={LABEL_MARGIN}
        >
          <Label
            {...commonLabelProps}
            value={labelByMetric[lineMetric]}
            angle={90}
            position="insideRight"
            dx={4}
          />
        </YAxis>
        <Tooltip
          content={<DashboardChartTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Bar dataKey="barValue" yAxisId="left" fill={barColor} />
        <Line
          dataKey="lineValue"
          yAxisId="right"
          type="linear"
          strokeWidth={2}
          stroke={lineColor}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
