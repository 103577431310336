import { useTranslation } from 'next-i18next';
import React from 'react';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';
import { DropdownButton } from 'stars-components/lib/components/DropdownButton';
import { MenuItem } from 'stars-components/lib/components/DropdownMenu/MenuItem';
import { MenuList } from 'stars-components/lib/components/DropdownMenu/MenuList';
import { useDropdownMenu } from 'stars-hooks/lib/hooks/useDropdownMenu';
import { useToggle } from 'stars-hooks/lib/hooks/useToggle';
import { RatingDialog } from 'components/Dashboard/components/ManagerCard/components/rating/RatingDialog';

interface IManagerMenuButtonProps {
  appointmentLink: string;
}

export const ManagerMenuButton: React.FC<IManagerMenuButtonProps> = ({
  appointmentLink,
}) => {
  const { t } = useTranslation('common');
  const { openMenu, anchorEl, closeMenu, isOpen } = useDropdownMenu();
  const {
    isOpen: isRatingDialogOpen,
    toggleOpen: toggleRatingDialogOpen,
    toggleClose: toggleRatingDialogClose,
  } = useToggle();

  const ratingClickHandler = (): void => {
    closeMenu();
    toggleRatingDialogOpen();
  };

  return (
    <>
      <DropdownButton
        sx={buttonSx}
        dropdownContent={
          <MenuList>
            <>
              <MenuItem
                text={<Typography>{t('rate_manager')}</Typography>}
                onClick={ratingClickHandler}
              />
              <MenuItem
                text={<Typography>{t('book_call')}</Typography>}
                href={appointmentLink}
                target="_blank"
                onClick={closeMenu}
              />
            </>
          </MenuList>
        }
        isOpen={isOpen}
        closeMenu={closeMenu}
        openMenu={openMenu}
        anchorEl={anchorEl}
      />
      <RatingDialog
        isOpen={isRatingDialogOpen}
        onClose={toggleRatingDialogClose}
      />
    </>
  );
};

const buttonSx: SystemStyleObject = {
  border: '1px solid',
  borderColor: 'grey.400',
  color: 'primary.main',

  '&:hover': {
    borderColor: 'primary.main',
    backgroundColor: 'secondary.dark',
  },
};
