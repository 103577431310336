import React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useAccountManager } from 'stars-hooks/lib/hooks/api/users/useAccountManager';
import { LoadingAnimation } from 'components/Dashboard/components/ManagerCard/components/LoadingAnimation';
import { ManagerInfo } from 'components/Dashboard/components/ManagerCard/components/ManagerInfo';
import { NoManager } from 'components/Dashboard/components/ManagerCard/components/NoManager';
import { DEFAULT_MANAGER_IDS } from 'components/Dashboard/components/ManagerCard/constants/defaultManagerIds';

export const ManagerCard: React.FC = () => {
  const { data: accountManager, error } = useAccountManager();
  const idDefaultManage =
    accountManager && DEFAULT_MANAGER_IDS.includes(accountManager.id);

  if (!accountManager && !error) {
    return (
      <Paper sx={paperSx}>
        <LoadingAnimation />
      </Paper>
    );
  }

  if (idDefaultManage || !accountManager) {
    return (
      <Paper sx={paperSx}>
        <NoManager />
      </Paper>
    );
  }

  return (
    <Paper sx={paperSx}>
      <ManagerInfo {...accountManager} />
    </Paper>
  );
};

const paperSx: SxProps<Theme> = ({ shape }) => ({
  minHeight: 256,
  borderRadius: shape.borderRadiusLarge,
  boxShadow: shape.boxShadow,
  position: 'relative',
});
