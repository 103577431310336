import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TooltipProps } from 'recharts';
import { formatDate } from 'stars-utils/lib/helpers/dates/formatDate';
import { useDashboardContext } from 'components/Dashboard/components/DashboardProvider';
import { formatValueByMetric } from 'components/Dashboard/helpers/formatValueByMetric';
import { useChartColors } from 'components/Dashboard/hooks/useChartColors';

export const DashboardChartTooltip: React.FC<
  TooltipProps<string, string>
> = ({ payload, label }) => {
  const { barMetric, lineMetric } = useDashboardContext();
  const { barColor, lineColor } = useChartColors();

  const barValue = formatValueByMetric(
    Number(payload?.[0]?.value) || 0,
    barMetric,
  );
  const lineValue = formatValueByMetric(
    Number(payload?.[1]?.value) || 0,
    lineMetric,
  );

  return (
    <Paper sx={rootSx}>
      <Grid container alignItems="center">
        <Box sx={[indicatorSx, { background: barColor }]} />
        <Typography>{barValue}</Typography>
      </Grid>
      <Grid container alignItems="center">
        <Box sx={[indicatorSx, { background: lineColor }]} />
        <Typography>{lineValue}</Typography>
      </Grid>
      <Typography variant="caption" sx={dateSx}>
        {formatDate(label)}
      </Typography>
    </Paper>
  );
};

const rootSx = {
  padding: 1,
};

const indicatorSx = {
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  marginRight: 1,
};

const dateSx = {
  color: 'grey.600',
};
