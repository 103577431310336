import React from 'react';
import { AmountLabel } from 'stars-components/lib/components/dashboard/AmountLabel';
import { useEarning } from 'stars-hooks/lib/hooks/api/statistics/useEarning';
import { DashboardDateRange } from 'components/Dashboard/components/DashboardDateRange';
import { DashboardMetricSelect } from 'components/Dashboard/components/DashboardMetricSelect';
import { DashboardOverview } from 'components/Dashboard/components/DashboardOverview';
import { useDashboardContext } from 'components/Dashboard/components/DashboardProvider';
import { usePublisherAdvancedAmountProps } from 'components/Dashboard/components/PublisherAdvancedDashboard/components/PublisherAdvancedDashboardOverview/hooks/usePublisherAdvancedAmountProps';
import { usePublisherAdvancedChartData } from 'components/Dashboard/components/PublisherAdvancedDashboard/hooks/usePublisherSuperuserChartData';
import { useChartColors } from 'components/Dashboard/hooks/useChartColors';
import { useManagerId } from 'components/Dashboard/hooks/useManagerId';
import { useStatisticsUserId } from 'components/Dashboard/hooks/useStatisticsUserId';
import { IOverviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

export const PublisherAdvancedDashboardOverview: React.FC = () => {
  const { barColor, lineColor } = useChartColors();
  const {
    dateRange,
    setDateRange,
    barMetric,
    lineMetric,
    setBarMetric,
    setLineMetric,
  } = useDashboardContext();
  const barAmountProps = usePublisherAdvancedAmountProps({
    dateRange,
    metric: barMetric,
  });
  const lineAmountProps = usePublisherAdvancedAmountProps({
    dateRange,
    metric: lineMetric,
  });
  const managerId = useManagerId();
  const userId = useStatisticsUserId();
  const { data, error } = useEarning({ userId, managerId });

  const isLoadingEarning = !data && !error;

  const chartData = usePublisherAdvancedChartData({
    dateFrom: dateRange[0],
    dateTo: dateRange[1],
    mapper: (date, metric): IOverviewChartRecord => ({
      date,
      barValue: metric?.[barMetric] || 0,
      lineValue: metric?.[lineMetric] || 0,
    }),
  });

  const hiddenMetrics = [Metric.LEADS];

  const toolbarItems = [
    <DashboardMetricSelect
      value={barMetric}
      onChange={setBarMetric}
      chartColor={barColor}
      hiddenMetrics={hiddenMetrics}
      key="DashboardBarMetricSelect"
    />,
    <DashboardMetricSelect
      value={lineMetric}
      onChange={setLineMetric}
      chartColor={lineColor}
      hiddenMetrics={hiddenMetrics}
      key="DashboardLineMetricSelect"
    />,
    <DashboardDateRange
      onChange={setDateRange}
      key="DashboardDateRange"
      months={data?.months || []}
      isLoading={isLoadingEarning}
    />,
  ];

  const amountItems = [
    <AmountLabel {...barAmountProps} key={0} color={barColor} />,
    <AmountLabel {...lineAmountProps} key={1} color={lineColor} />,
  ];

  return (
    <DashboardOverview
      toolbarItems={toolbarItems}
      amountItems={amountItems}
      chartData={chartData}
    />
  );
};
