import dayjs from 'dayjs';
import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { DATE_MONTH_FORMAT } from 'stars-utils/lib/constants/dates';

export const DATE_PREVIEW_CHART_LABEL_FORMAT = 'LLL`dd';

export const CURRENT_MONTH = dayjs().format(DATE_MONTH_FORMAT);

export const CURRENT_DATE_RANGE: INonEmptyDateRange = [
  dayjs().startOf('month'),
  dayjs().endOf('month'),
];
