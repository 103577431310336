import React from 'react';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { AccountBalance } from 'components/Dashboard/components/AccountBalance';
import { AdvertiserDashboardOverview } from 'components/Dashboard/components/AdvertiserDashboardOverview';
import { DashboardLayout } from 'components/Dashboard/components/DashboardLayout';
import { DashboardProvider } from 'components/Dashboard/components/DashboardProvider';
import { MobileRtbGuideTiles } from 'components/Dashboard/components/MobileRtbGuideTiles';
import { RtbGuideTiles } from 'components/Dashboard/components/RtbGuideTiles';
import { SpentThisMonth } from 'components/Dashboard/components/SpentThisMonth';
import { SpentToday } from 'components/Dashboard/components/SpentToday';

export const DspDashboard: React.FC = () => {
  const { isMobile } = useScreenSize();

  const accountingContent = [
    <AccountBalance key="AccountBalance" />,
    <SpentToday key="SpentToday" />,
    <SpentThisMonth key="SpentThisMonth" />,
  ];

  return (
    <DashboardProvider>
      {!isMobile ? (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<AdvertiserDashboardOverview />}
          guideContent={<RtbGuideTiles />}
        />
      ) : (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<AdvertiserDashboardOverview />}
          guideContent={<MobileRtbGuideTiles />}
        />
      )}
    </DashboardProvider>
  );
};
