import { useTranslation } from 'next-i18next';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { usePublisherAdvancedCustomReportByDay } from 'stars-hooks/lib/hooks/api/statistics/usePublisherAdvancedCustomReportByDay';
import { PublisherStatisticsTile } from 'components/Dashboard/components/PublisherStatisticsTile';
import { useDashboardUserId } from 'components/Dashboard/hooks/useDashboardUserId';

export const PublisherStatisticsToday: React.FC = () => {
  const userId = useDashboardUserId();
  const { t } = useTranslation('common');

  const today = dayjs();

  const { data, error } = usePublisherAdvancedCustomReportByDay({
    userId,
    // Нужны данные для сегодняшнего дня,
    // но запрос отправляем на получение данных за весь текущий месяц
    // т. к. такой же запрос отправляется в компоненте PublisherStatisticsThisMonth
    // Это позволит взять данные из кеша
    dateFrom: today.startOf('month').toDate(),
    dateTo: today.toDate(),
  });

  const isLoading = !data && !error;

  const todayStatistics = data?.[data?.length - 1];

  return (
    <PublisherStatisticsTile
      title={t('Today')}
      icon={<AccessTimeIcon />}
      isLoading={isLoading}
      turnover={todayStatistics?.amount}
      earnings={todayStatistics?.revenue}
      revenue={todayStatistics?.net_revenue}
    />
  );
};
