import { useTranslation } from 'next-i18next';
import { Metric } from 'components/Dashboard/types/metric';

export const useMetricLabelByType = (): Record<Metric, string> => {
  const { t } = useTranslation('common');

  return Object.freeze({
    [Metric.IMPRESSIONS]: t('Impressions'),
    [Metric.AMOUNT]: t('Budget'),
    [Metric.ECPM]: t('ECPM'),
    [Metric.CLICKS]: t('Clicks'),
    [Metric.LEADS]: t('Leads'),
    [Metric.REVENUE]: t('Earnings'),
    [Metric.NET_REVENUE]: t('Revenue'),
  });
};
