import React from 'react';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { DashboardLayout } from 'components/Dashboard/components/DashboardLayout';
import { DashboardProvider } from 'components/Dashboard/components/DashboardProvider';
import { PublisherAdvancedDashboardOverview } from 'components/Dashboard/components/PublisherAdvancedDashboard/components/PublisherAdvancedDashboardOverview';
import { PublisherStatisticsThisMonth } from 'components/Dashboard/components/PublisherAdvancedDashboard/components/PublisherStatisticsThisMonth';
import { PublisherStatisticsThisQuarter } from 'components/Dashboard/components/PublisherAdvancedDashboard/components/PublisherStatisticsThisQuarter';
import { PublisherStatisticsToday } from 'components/Dashboard/components/PublisherAdvancedDashboard/components/PublisherStatisticsToday';
import { PublisherGuideTiles } from 'components/PublisherGuideTiles';
import { PublisherMobileGuideTiles } from 'components/PublisherMobileGuideTiles';

export const PublisherAdvancedDashboard: React.FC = () => {
  const { isDesktop } = useScreenSize();

  const accountingContent = [
    <PublisherStatisticsToday key="PublisherStatisticsTile" />,
    <PublisherStatisticsThisMonth key="PublisherStatisticsThisMonth" />,
    <PublisherStatisticsThisQuarter key="PublisherStatisticsThisQuarter" />,
  ];

  return (
    <DashboardProvider>
      {isDesktop ? (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<PublisherAdvancedDashboardOverview />}
          guideContent={<PublisherGuideTiles direction="row" />}
        />
      ) : (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<PublisherAdvancedDashboardOverview />}
          guideContent={<PublisherMobileGuideTiles />}
        />
      )}
    </DashboardProvider>
  );
};
