import { useTranslation } from 'next-i18next';
import React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs from 'dayjs';
import { usePublisherStatistics } from 'components/Dashboard/components/PublisherAdvancedDashboard/hooks/usePublisherStatistics';
import { PublisherStatisticsTile } from 'components/Dashboard/components/PublisherStatisticsTile';

export const PublisherStatisticsThisMonth: React.FC = () => {
  const { t } = useTranslation('common');

  const today = dayjs();
  const previousMonth = today.subtract(1, 'month');

  const statistics = usePublisherStatistics({
    currentPeriod: {
      dateFrom: today.startOf('month'),
      dateTo: today,
    },
    previousPeriod: {
      dateFrom: previousMonth.startOf('month'),
      dateTo: previousMonth.endOf('month'),
    },
  });

  return (
    <PublisherStatisticsTile
      title={t('This month')}
      icon={<DateRangeIcon />}
      {...statistics}
    />
  );
};
