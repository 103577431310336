import React from 'react';
import { GuideTiles } from 'components/GuideTiles';
import { usePublisherGuideTileList } from 'hooks/usePublisherGuideTileList';

interface IPublisherGuideTilesProps {
  direction?: 'column' | 'row';
}

export const PublisherGuideTiles: React.FC<
  IPublisherGuideTilesProps
> = ({ direction }) => {
  const isRow = direction === 'row';
  const guideTileList = usePublisherGuideTileList(isRow);

  return (
    <GuideTiles direction={direction} guideTileList={guideTileList} />
  );
};
