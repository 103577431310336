import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContentLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/ContentLoadingAnimation';
import { LinearLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/LinearLoadingAnimation';
import { getChartLoaderBarsHeight } from 'components/Dashboard/helpers/getChartLoaderBarsHeight';

const BAR_COUNT = 30;
const BAR_GAP_PERCENT = 1;
const X_AXIS_LABEL_COUNT = 8;
const BAR_WIDTH = `${100 / BAR_COUNT - BAR_GAP_PERCENT}%`;

const barList = getChartLoaderBarsHeight(BAR_COUNT);
const X_AXIS_LABELS = new Array(X_AXIS_LABEL_COUNT).fill(undefined);

export const DashboardChartLoadingAnimation: React.FC = () => {
  return (
    <>
      <Box sx={chartContainerSx}>
        <ContentLoadingAnimation width="100%" height="100%">
          {barList.map((bar, index) => {
            const x = `${
              (100 / BAR_COUNT) * index + BAR_GAP_PERCENT / 2
            }%`;
            const y = `${100 - bar.heightPercent}%`;

            return (
              <rect
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                rx="2"
                ry="2"
                x={x}
                y={y}
                width={BAR_WIDTH}
                height={`${bar.heightPercent}%`}
              />
            );
          })}
        </ContentLoadingAnimation>
      </Box>
      <Grid
        container
        justifyContent="space-around"
        alignItems="flex-end"
        sx={xAxisContainerSx}
      >
        {X_AXIS_LABELS.map((_, index) => {
          const width = `${100 / X_AXIS_LABEL_COUNT / 2}%`;

          return (
            <LinearLoadingAnimation
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              height={12}
              width={width}
              rx={2}
              ry={2}
            />
          );
        })}
      </Grid>
    </>
  );
};

const chartContainerSx = {
  height: '94%',
  paddingRight: '16px',
  paddingLeft: '16px',
};

const xAxisContainerSx = {
  height: '6%',
};
