export const getChartLoaderBarsHeight = (
  count: number,
): ReadonlyArray<{
  heightPercent: number;
}> =>
  Array.from(
    {
      length: count,
    },
    () => ({
      heightPercent: Math.floor(Math.random() * 100),
    }),
  );
