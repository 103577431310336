import { useTranslation } from 'next-i18next';
import React from 'react';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { useCopyText } from 'stars-hooks/lib/hooks/useCopyText';
import { ClientKeyRow } from 'components/Dashboard/components/ClientKeysGuideTileContent/components/ClientKeyRow';

export const ClientKeysGuideTileContent: React.FC = () => {
  const { copyText } = useCopyText();
  const { clientId = '', clientSecret = '' } = useUserDataContext();
  const { t } = useTranslation('common');

  const copyClientIdHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    copyText(clientId);
  };

  const copyClientSecretHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    copyText(clientSecret);
  };

  if (!clientId && !clientSecret) {
    return <>–</>;
  }

  return (
    <>
      <ClientKeyRow
        label={t('Client ID')}
        value={clientId}
        onCopy={copyClientIdHandler}
      />
      <ClientKeyRow
        label={t('Client Secret')}
        value={clientSecret}
        onCopy={copyClientSecretHandler}
      />
    </>
  );
};
