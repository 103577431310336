import { useTranslation } from 'next-i18next';
import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import DescriptionIcon from '@mui/icons-material/Description';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { ClientKeysGuideTileContent } from 'components/Dashboard/components/ClientKeysGuideTileContent';
import { IGuideTileProps } from 'types/guideTiles';

export const useRtbGuideTileList =
  (): ReadonlyArray<IGuideTileProps> => {
    const { t } = useTranslation('common');
    const { palette } = useTheme();
    const { isTablet, isDesktop, isLargeDesktop } = useScreenSize();

    const tiles: ReadonlyArray<IGuideTileProps> = [
      {
        title: t('TrafficStars API'),
        text: t(
          'You can find all TrafficStars API documentation here',
        ),
        Icon: CodeIcon,
        backgroundIcon: '#ff9e18',
        href: 'https://docs.trafficstars.com',
      },
      {
        title: t('Your API credentials'),
        text: <ClientKeysGuideTileContent />,
        backgroundIcon: palette.success.main,
        Icon: VpnKeyIcon,
      },
    ];

    if (isTablet || (isDesktop && !isLargeDesktop)) {
      return tiles;
    }

    return [
      ...tiles,
      {
        title: t('oRTB integration documentation'),
        text: t('Connect via openRTB protocol'),
        backgroundIcon: '#7d62f4',
        Icon: DescriptionIcon,
        href: 'https://rtb-docs.trafficstars.com',
      },
      {
        title: t('JSON/XML integration documentation'),
        text: t('Connect via JSON/XML protocol'),
        backgroundIcon: '#ff1547',
        Icon: WarningIcon,
        href: 'https://rtb-docs.trafficstars.com/#/_apok',
      },
    ];
  };
