import { useTranslation } from 'next-i18next';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { useSpending } from 'stars-hooks/lib/hooks/api/statistics/useSpending';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { useAdvertiserAmountProps } from 'components/Dashboard/components/AdvertiserDashboardOverview/hooks/useAdvertiserAmountProps';
import { WeekChartPreview } from 'components/Dashboard/components/WeekChartPreview';
import { CURRENT_DATE_RANGE } from 'components/Dashboard/constants/dates';
import { formatSimpleWeekChartDate } from 'components/Dashboard/helpers/formatSimpleWeekChartDate';
import { getWeekAgoDate } from 'components/Dashboard/helpers/getWeekAgoDate';
import { isTodayFirstDayOfMonth } from 'components/Dashboard/helpers/isTodayFirstDayOfMonth';
import { useAdvertiserChartData } from 'components/Dashboard/hooks/useAdvertiserChartData';
import { useDashboardUserId } from 'components/Dashboard/hooks/useDashboardUserId';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

interface ISpentTodayProps {
  title?: string;
}

export const SpentToday: React.FC<ISpentTodayProps> = ({ title }) => {
  const { t } = useTranslation('common');
  const userId = useDashboardUserId();
  const { data, error } = useSpending({ userId });

  const isSpendingLoading = !data && !error;
  const todayDate = dayjs();

  const { data: chartData } = useAdvertiserChartData({
    dateFrom: getWeekAgoDate(),
    dateTo: todayDate,
    mapper: (date, metric): IPreviewChartRecord => ({
      date: formatSimpleWeekChartDate(date),
      value: metric?.[Metric.AMOUNT] || 0,
    }),
  });

  const monthSpending = useAdvertiserAmountProps({
    dateRange: CURRENT_DATE_RANGE,
    metric: Metric.AMOUNT,
  });
  const value = isTodayFirstDayOfMonth()
    ? monthSpending.value
    : data?.today_spending && formatCurrency(data.today_spending);
  const isLoading = isTodayFirstDayOfMonth()
    ? monthSpending.isLoading
    : isSpendingLoading;

  return (
    <AccountingWidget
      icon={<AccessTimeIcon color="primary" />}
      title={title || t('Spent today')}
      amountProps={{
        value,
        unit: 'USD',
        isLoading,
      }}
      content={<WeekChartPreview data={chartData} />}
    />
  );
};
