import { useTranslation } from 'next-i18next';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { useEarning } from 'stars-hooks/lib/hooks/api/statistics/useEarning';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { WeekChartPreview } from 'components/Dashboard/components/WeekChartPreview';
import { formatSimpleWeekChartDate } from 'components/Dashboard/helpers/formatSimpleWeekChartDate';
import { getWeekAgoDate } from 'components/Dashboard/helpers/getWeekAgoDate';
import { useManagerId } from 'components/Dashboard/hooks/useManagerId';
import { usePublisherChartData } from 'components/Dashboard/hooks/usePublisherChartData';
import { useStatisticsUserId } from 'components/Dashboard/hooks/useStatisticsUserId';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

export const TodayEarnings: React.FC = () => {
  const { t } = useTranslation('common');
  const managerId = useManagerId();
  const userId = useStatisticsUserId();
  const { data, error } = useEarning({ userId, managerId });

  const isLoading = !data && !error;

  const { data: chartData } = usePublisherChartData({
    dateFrom: getWeekAgoDate(),
    dateTo: dayjs(),
    mapper: (date, metric): IPreviewChartRecord => ({
      date: formatSimpleWeekChartDate(date),
      value: metric?.[Metric.AMOUNT] || 0,
    }),
  });

  return (
    <AccountingWidget
      icon={<AccessTimeIcon color="primary" />}
      title={t('Today earnings')}
      amountProps={{
        value:
          data?.today_earning && formatCurrency(data.today_earning),
        unit: 'USD',
        isLoading,
      }}
      content={<WeekChartPreview data={chartData} />}
    />
  );
};
