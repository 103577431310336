import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Text,
  XAxis,
} from 'recharts';
import { WeekChartPreviewLoadingAnimation } from 'components/Dashboard/components/WeekChartPreview/components/WeekChartPreviewLoadingAnimation';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';

interface ISimpleWeekChartProps {
  data?: IPreviewChartRecord[];
}

export const WeekChartPreview: React.FC<ISimpleWeekChartProps> = ({
  data,
}) => {
  const { palette, typography } = useTheme();

  if (!data) {
    return <WeekChartPreviewLoadingAnimation />;
  }

  if (!data.length) {
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} barCategoryGap="30%">
        <XAxis
          strokeWidth={0}
          stroke={palette.grey[600]}
          fontSize="0.7rem"
          fontFamily={typography.fontFamily}
          dataKey="date"
          height={25}
          dy={5}
          interval={0}
          tick={(props): ReactElement<SVGElement> => {
            const {
              index,
              payload: { value },
            } = props;
            const isLastLabel = index === data.length - 1;

            if (isLastLabel) {
              props.fill = palette.primary.main;
            }

            return <Text {...props}>{value}</Text>;
          }}
        />
        <Bar
          dataKey="value"
          stroke={palette.secondary.main}
          fill={palette.secondary.main}
          radius={2}
        >
          {data.map(({ date }, index) => {
            const isLastBar = index === data.length - 1;

            return (
              <Cell
                key={date}
                fill={
                  isLastBar
                    ? palette.primary.main
                    : palette.secondary.main
                }
                stroke={
                  isLastBar
                    ? palette.primary.main
                    : palette.secondary.main
                }
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
