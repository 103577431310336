import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { IGuideTileProps } from 'types/guideTiles';

export const GuideTile: React.FC<IGuideTileProps> = ({
  title,
  text,
  Icon,
  backgroundIcon,
  href,
}) => {
  return (
    <Link href={href} target="_blank" sx={linkSx} underline="none">
      <Grid container sx={rootSx} wrap="nowrap" alignItems="center">
        <Grid item>
          <Grid
            sx={[iconWrapSx, { background: backgroundIcon }]}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Icon sx={iconSx} />
          </Grid>
        </Grid>
        <Grid item pl={2}>
          <Typography variant="h6" sx={titleSx}>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

const rootSx = {
  paddingLeft: 2.5,
  paddingY: 0,
  paddingRight: 1.75,
  height: '100%',
};

const linkSx = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
};

const iconWrapSx = {
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  marginTop: 0.25,
};

const iconSx = {
  color: '#fff',
};

const titleSx = {
  marginBottom: 1,
  lineHeight: 1.33,
  color: '#2a2a34',
};
