import { Dayjs } from 'dayjs';
import {
  ICustomReportByDay,
  ICustomReportByDayResponseModel,
} from 'stars-api/lib/types/statistics/customReportByDayResponseModel';
import { DATE_REQUEST_FORMAT } from 'stars-utils/lib/constants/dates';
import { formatDate } from 'stars-utils/lib/helpers/dates/formatDate';
import { getDaysRange } from 'components/Dashboard/helpers/getDaysRange';

export type IChartDataMapper<T> = (
  date: Date,
  metric?: ICustomReportByDay,
) => T;

export interface IGetChartDataParams<T> {
  metricsData?: ICustomReportByDayResponseModel;
  dateFrom: Dayjs;
  dateTo: Dayjs;
  mapper: IChartDataMapper<T>;
}

export const createChartData = <T>({
  metricsData,
  dateFrom,
  dateTo,
  mapper,
}: IGetChartDataParams<T>): T[] => {
  if (!metricsData?.length) {
    return [];
  }

  return getDaysRange(dateFrom, dateTo).map((date) => {
    const metric = metricsData.find(
      (item) =>
        formatDate(date, DATE_REQUEST_FORMAT) ===
        formatDate(item.day, DATE_REQUEST_FORMAT),
    );

    return mapper(date, metric);
  });
};
