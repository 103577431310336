import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps, SystemStyleObject } from '@mui/system';
import { openIntercomChat } from 'stars-utils/lib/helpers/openIntercomChat';
import avatar from 'components/Dashboard/components/ManagerCard/assets/images/avatar.png';

export const NoManager: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Grid
        container
        sx={containerSx}
        flexDirection="column"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="h4" sx={titleSx}>
            {t('want_manager')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={descriptionSx}>
            {t('contact_support')}{' '}
            <Link
              href="https://trafficstars.com/faq/articles/why-do-you-need-a-trafficstars-advertising-account-manager"
              target="_blank"
              underline="hover"
            >
              FAQ
            </Link>
          </Typography>
        </Grid>
        <Grid item mt={2.5}>
          <Button
            color="primary"
            variant="contained"
            sx={buttonSx}
            onClick={openIntercomChat}
          >
            {t('chat_with_us')}
          </Button>
        </Grid>
      </Grid>
      <Box sx={imageSx}>
        <Image src={avatar} alt="hey" />
      </Box>
    </>
  );
};

const smallContainerSx: SystemStyleObject = {
  width: 'calc(100% - 145px)',
};
const containerSx: SxProps<Theme> = ({ breakpoints }) => ({
  px: 2.5,
  py: 2,
  height: '100%',
  width: 'calc(100% - 185px)',

  [breakpoints.down(465)]: {
    ...smallContainerSx,
  },
  [breakpoints.between('sm', 900)]: {
    ...smallContainerSx,
  },
});

const smallTitleSx: SystemStyleObject = {
  fontSize: 22,
};
const titleSx: SxProps<Theme> = ({ breakpoints }) => ({
  marginBottom: 1.5,
  fontWeight: 500,
  fontSize: 34,

  [breakpoints.down(465)]: {
    ...smallTitleSx,
  },
  [breakpoints.between('sm', 900)]: {
    ...smallTitleSx,
  },
  [breakpoints.between('lg', 1385)]: {
    ...smallTitleSx,
  },
});

const descriptionSx: SxProps = {
  fontSize: 18,
  color: 'grey.600',
  lineHeight: 1.33,
  letterSpacing: '-0.1px',
};

const smallImageSx: SystemStyleObject = {
  '& img': {
    width: 190,
  },
};
const imageSx: SxProps<Theme> = ({ breakpoints }) => ({
  position: 'absolute',
  right: -15,
  display: 'flex',
  justifyContent: 'center',
  top: '50%',
  transform: 'translate(0, -50%)',
  '-webkit-transform': 'translate(0, -50%)',
  '-moz-transform': 'translate(0, -50%)',
  '-ms-transform': 'translate(0, -50%)',
  '-o-transform': 'translate(0, -50%)',

  '& img': {
    width: 230,
    height: 'auto',
  },

  [breakpoints.down(465)]: {
    ...smallImageSx,
  },
  [breakpoints.between('sm', 900)]: {
    ...smallImageSx,
  },
});

const buttonSx: SxProps = {
  textTransform: 'none',
  fontSize: 16,
};
