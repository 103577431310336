import { ICustomReportByDayResponseModel } from 'stars-api/lib/types/statistics/customReportByDayResponseModel';
import { IAmountLabelProps } from 'stars-components/lib/components/dashboard/AmountLabel';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { isMetricCurrency } from 'components/Dashboard/helpers/isMetricCurrency';
import { useMetricLabelByType } from 'components/Dashboard/hooks/useMetricLabelByType';
import { Metric } from 'components/Dashboard/types/metric';
import { calculateTotalWithinDateRange } from 'components/Dashboard/helpers/calculateTotalWithinDateRange';

interface IUseAmountProps {
  metric: Metric;
  data?: ICustomReportByDayResponseModel;
  dateRange: INonEmptyDateRange;
}

export const useAmountProps = (
  props: IUseAmountProps,
): IAmountLabelProps => {
  const labelByMetric = useMetricLabelByType();

  const { metric } = props;

  const amount = calculateTotalWithinDateRange(props);

  return isMetricCurrency(metric)
    ? {
        value: amount && formatCurrency(amount),
        unit: 'USD',
        label: labelByMetric[metric],
      }
    : {
        value: amount,
        isShort: true,
        label: labelByMetric[metric],
      };
};
