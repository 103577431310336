import { useTranslation } from 'next-i18next';
import React from 'react';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { AdvertiserGuideTiles } from 'components/AdvertiserGuideTiles';
import { AdvertiserMobileGuideTiles } from 'components/AdvertiserMobileGuideTiles';
import { AccountBalance } from 'components/Dashboard/components/AccountBalance';
import { AdvertiserDashboardOverview } from 'components/Dashboard/components/AdvertiserDashboardOverview';
import { DashboardLayout } from 'components/Dashboard/components/DashboardLayout';
import { DashboardProvider } from 'components/Dashboard/components/DashboardProvider';
import { SpentThisMonth } from 'components/Dashboard/components/SpentThisMonth';
import { SpentThisQuarter } from 'components/Dashboard/components/SpentThisQuarter';
import { SpentToday } from 'components/Dashboard/components/SpentToday';

export const AdvertiserDashboard: React.FC = () => {
  const { isMobile } = useScreenSize();
  const { isSuperUser } = useUserDataContext();
  const { t } = useTranslation('common');

  const accountingContent = isSuperUser
    ? [
        <SpentToday
          key="SpentToday"
          title={t('Clients spent today')}
        />,
        <SpentThisMonth
          key="SpentThisMonth"
          title={t('Clients spent this month')}
        />,
        <SpentThisQuarter key="SpentThisQuarter" />,
      ]
    : [
        <AccountBalance key="AccountBalance" />,
        <SpentToday key="SpentToday" />,
        <SpentThisMonth key="SpentThisMonth" />,
      ];

  return (
    <DashboardProvider>
      {!isMobile ? (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<AdvertiserDashboardOverview />}
          guideContent={<AdvertiserGuideTiles direction="row" />}
        />
      ) : (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<AdvertiserDashboardOverview />}
          guideContent={<AdvertiserMobileGuideTiles />}
        />
      )}
    </DashboardProvider>
  );
};
