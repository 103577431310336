import { accessControl } from 'stars-access-control';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';

export const useManagerId = (): number | undefined => {
  const { userId, isSuperUser } = useUserDataContext();

  if (isSuperUser) {
    return undefined;
  }

  if (
    accessControl.role.isRtbManager() ||
    accessControl.role.isPublisherAccountManager() ||
    accessControl.role.isAdvertiserAccountManager() ||
    accessControl.role.isPerformanceManager()
  ) {
    return userId;
  }

  return undefined;
};
