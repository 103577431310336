import React from 'react';
import { AmountLabel } from 'stars-components/lib/components/dashboard/AmountLabel';
import { useSpending } from 'stars-hooks/lib/hooks/api/statistics/useSpending';
import { useAdvertiserAmountProps } from 'components/Dashboard/components/AdvertiserDashboardOverview/hooks/useAdvertiserAmountProps';
import { DashboardDateRange } from 'components/Dashboard/components/DashboardDateRange';
import { DashboardMetricSelect } from 'components/Dashboard/components/DashboardMetricSelect';
import { DashboardOverview } from 'components/Dashboard/components/DashboardOverview';
import { useDashboardContext } from 'components/Dashboard/components/DashboardProvider';
import { useAdvertiserChartData } from 'components/Dashboard/hooks/useAdvertiserChartData';
import { useChartColors } from 'components/Dashboard/hooks/useChartColors';
import { useDashboardUserId } from 'components/Dashboard/hooks/useDashboardUserId';
import { IOverviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

export const AdvertiserDashboardOverview: React.FC = () => {
  const { barColor, lineColor } = useChartColors();
  const {
    dateRange,
    setDateRange,
    barMetric,
    lineMetric,
    setBarMetric,
    setLineMetric,
  } = useDashboardContext();
  const barAmountProps = useAdvertiserAmountProps({
    dateRange,
    metric: barMetric,
  });
  const lineAmountProps = useAdvertiserAmountProps({
    dateRange,
    metric: lineMetric,
  });
  const userId = useDashboardUserId();
  const { data, error } = useSpending({ userId });

  const isLoadingSpending = !data && !error;

  const chartData = useAdvertiserChartData({
    dateFrom: dateRange[0],
    dateTo: dateRange[1],
    mapper: (date, metric): IOverviewChartRecord => ({
      date,
      barValue: metric?.[barMetric] || 0,
      lineValue: metric?.[lineMetric] || 0,
    }),
  });

  const hiddenMetrics = [Metric.REVENUE, Metric.NET_REVENUE];

  const toolbarItems = [
    <DashboardMetricSelect
      value={barMetric}
      onChange={setBarMetric}
      chartColor={barColor}
      hiddenMetrics={hiddenMetrics}
      key="DashboardBarMetricSelect"
    />,
    <DashboardMetricSelect
      value={lineMetric}
      onChange={setLineMetric}
      chartColor={lineColor}
      hiddenMetrics={hiddenMetrics}
      key="DashboardLineMetricSelect"
    />,
    <DashboardDateRange
      onChange={setDateRange}
      key="DashboardDateRange"
      months={data?.months}
      isLoading={isLoadingSpending}
    />,
  ];

  const amountItems = [
    <AmountLabel {...barAmountProps} key={0} color={barColor} />,
    <AmountLabel {...lineAmountProps} key={1} color={lineColor} />,
  ];

  return (
    <DashboardOverview
      toolbarItems={toolbarItems}
      amountItems={amountItems}
      chartData={chartData}
    />
  );
};
