import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface IDashboardSelectProps {
  value: string;
  onChange(value: string): void;
  options: ReadonlyArray<{ value: string; label: string }>;
  width?: number;
  dataTestKey?: string;
}

export const DashboardSelect: React.FC<IDashboardSelectProps> = ({
  value,
  options,
  onChange,
  width,
  dataTestKey = '',
}) => {
  const selectOnChange: SelectInputProps['onChange'] = (e) => {
    if (typeof e.target.value === 'string') {
      onChange(e.target.value);
    }
  };

  return (
    <Select
      autoWidth
      variant="standard"
      value={value}
      onChange={selectOnChange}
      data-test={`${dataTestKey}DashboardSelectDate`}
      sx={[rootSx, { width }]}
    >
      {options.map((option, idx) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-test={`${dataTestKey}DashboardSelectDateValue${idx}`}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const rootSx = {
  '&:before': {
    display: 'none',
  },
  '&:after': {
    display: 'none',
  },

  '.MuiSelect-select:focus': {
    backgroundColor: 'white',
  },
};
