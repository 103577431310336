import { useTranslation } from 'next-i18next';
import React from 'react';
import Filter3Icon from '@mui/icons-material/Filter3';
import dayjs from 'dayjs';
import { usePublisherStatistics } from 'components/Dashboard/components/PublisherAdvancedDashboard/hooks/usePublisherStatistics';
import { PublisherStatisticsTile } from 'components/Dashboard/components/PublisherStatisticsTile';

export const PublisherStatisticsThisQuarter: React.FC = () => {
  const { t } = useTranslation('common');

  const today = dayjs();
  const previuosQuarter = dayjs().subtract(1, 'quarter');

  const statistics = usePublisherStatistics({
    currentPeriod: {
      dateFrom: today.startOf('quarter'),
      dateTo: today,
    },
    previousPeriod: {
      dateFrom: previuosQuarter.startOf('quarter'),
      dateTo: previuosQuarter.endOf('quarter'),
    },
  });

  return (
    <PublisherStatisticsTile
      title={t('This quarter')}
      icon={<Filter3Icon />}
      {...statistics}
    />
  );
};
