import React from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

interface IClientKeyRowProps {
  label: string;
  value: string;
  onCopy(e: React.MouseEvent<HTMLButtonElement>): void;
}

export const ClientKeyRow: React.FC<IClientKeyRowProps> = ({
  label,
  value,
  onCopy,
}) => {
  return (
    <Grid container wrap="nowrap" alignItems="flex-start">
      <Typography color="textSecondary" sx={valueSx}>
        <Typography
          color="textPrimary"
          sx={labelSx}
          variant="caption"
        >
          {label}:{' '}
        </Typography>
        {value}
      </Typography>
      <IconButton
        size="small"
        onClick={onCopy}
        color="primary"
        sx={iconBtnSx}
      >
        <FileCopyOutlinedIcon />
      </IconButton>
    </Grid>
  );
};

const labelSx = {
  fontSize: 'inherit',
  marginRight: 0.5,
  whiteSpace: 'nowrap',
  lineHeight: 1,
};

const valueSx = {
  fontSize: 'inherit',
  wordBreak: 'break-word',
};

const iconBtnSx = {
  position: 'relative',
  top: '-3px',
  marginLeft: 1,
};
