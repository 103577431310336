import { useTranslation } from 'next-i18next';
import React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { useEarning } from 'stars-hooks/lib/hooks/api/statistics/useEarning';
import { formatDate } from 'stars-utils/lib/helpers/dates/formatDate';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { MonthChartPreview } from 'components/Dashboard/components/MonthChartPreview';
import {
  CURRENT_DATE_RANGE,
  CURRENT_MONTH,
  DATE_PREVIEW_CHART_LABEL_FORMAT,
} from 'components/Dashboard/constants/dates';
import { isTodayFirstDayOfMonth } from 'components/Dashboard/helpers/isTodayFirstDayOfMonth';
import { useManagerId } from 'components/Dashboard/hooks/useManagerId';
import { usePublisherChartData } from 'components/Dashboard/hooks/usePublisherChartData';
import { useStatisticsUserId } from 'components/Dashboard/hooks/useStatisticsUserId';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

export const ThisMonthEarnings: React.FC = () => {
  const { t } = useTranslation('common');
  const managerId = useManagerId();
  const userId = useStatisticsUserId();

  const { data, error } = useEarning({
    month: CURRENT_MONTH,
    userId,
    managerId,
  });

  const isLoading = !data && !error;

  const shouldShowChart = !isTodayFirstDayOfMonth();

  const { data: chartData } = usePublisherChartData({
    dateFrom: CURRENT_DATE_RANGE[0],
    dateTo: CURRENT_DATE_RANGE[1],
    mapper: (date, metric): IPreviewChartRecord => ({
      date: formatDate(date, DATE_PREVIEW_CHART_LABEL_FORMAT),
      value: metric?.[Metric.AMOUNT] || 0,
    }),
  });

  return (
    <AccountingWidget
      icon={<DateRangeIcon color="primary" />}
      title={t('This month earnings')}
      amountProps={{
        value:
          data?.month_earning && formatCurrency(data.month_earning),
        unit: 'USD',
        isLoading,
      }}
      content={
        shouldShowChart && <MonthChartPreview data={chartData} />
      }
    />
  );
};
