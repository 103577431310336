import React from 'react';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { DashboardLayout } from 'components/Dashboard/components/DashboardLayout';
import { DashboardProvider } from 'components/Dashboard/components/DashboardProvider';
import { MobileRtbGuideTiles } from 'components/Dashboard/components/MobileRtbGuideTiles';
import { PublisherDashboardOverview } from 'components/Dashboard/components/PublisherDashboardOverview';
import { RtbGuideTiles } from 'components/Dashboard/components/RtbGuideTiles';
import { ThisMonthEarnings } from 'components/Dashboard/components/ThisMonthEarnings';
import { TodayEarnings } from 'components/Dashboard/components/TodayEarnings';
import { UnpaidEarnings } from 'components/Dashboard/components/UnpaidEarnings';

export const SspDashboard: React.FC = () => {
  const { isMobile } = useScreenSize();

  const accountingContent = [
    <UnpaidEarnings key="UnpaidEarnings" />,
    <TodayEarnings key="TodayEarnings" />,
    <ThisMonthEarnings key="ThisMonthEarnings" />,
  ];

  return (
    <DashboardProvider>
      {!isMobile ? (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<PublisherDashboardOverview />}
          guideContent={<RtbGuideTiles />}
        />
      ) : (
        <DashboardLayout
          accountingContent={accountingContent}
          chartContent={<PublisherDashboardOverview />}
          guideContent={<MobileRtbGuideTiles />}
        />
      )}
    </DashboardProvider>
  );
};
