import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MobileGuideTile } from 'components/MobileGuideTile';
import { IGuideTileProps } from 'types/guideTiles';

interface IMobileGuideTilesProps {
  guideTileList: ReadonlyArray<IGuideTileProps>;
}

export const MobileGuideTiles: React.FC<IMobileGuideTilesProps> = ({
  guideTileList,
}) => {
  return (
    <Paper elevation={2} sx={rootSx}>
      {guideTileList.map((guideTile) => (
        <Grid item key={guideTile.title} sx={guideTileWrapSx}>
          <MobileGuideTile {...guideTile} />
        </Grid>
      ))}
    </Paper>
  );
};

const rootSx: SxProps<Theme> = ({ shape }) => ({
  borderRadius: shape.borderRadiusLarge,
  boxShadow: shape.boxShadow,
});

const guideTileWrapSx = {
  boxShadow: 'inset 0 1px 0 0 #f2f2f2',
};
