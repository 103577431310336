import React from 'react';
import { ContentLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/ContentLoadingAnimation';

export const LoadingAnimation: React.FC = () => {
  return (
    <ContentLoadingAnimation height={256} width="100%">
      <rect x="24" y="21" width="80%" height="24" rx="1" ry="1" />
      <rect x="24" y="65" width="76" height="76" rx="16" ry="16" />
      <rect x="120" y="70" width="40%" height="16" rx="1" ry="1" />
      <rect x="120" y="100" width="30%" height="16" rx="1" ry="1" />
      <rect x="24" y="170" width="70%" height="16" rx="1" ry="1" />
      <rect x="24" y="200" width="50%" height="16" rx="1" ry="1" />
    </ContentLoadingAnimation>
  );
};
