import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { getApiErrorMessage } from 'stars-api/lib/helpers/getApiErrorMessage';
import { postAccountManagerRate } from 'stars-api/lib/methods/users/postAccountManagerRate';
import { ToastType, notify } from 'stars-notifier';

interface IUseRatingDialogProps {
  onClose: () => void;
}

interface IUseRatingDialogReturn {
  isLoading: boolean;
  isCommentValid: boolean;
  saveRating: () => Promise<void>;
  onCancel: () => void;
  rate?: number;
  comment: string;
  onRateChange: (rate: number) => void;
  onCommentChange: (comment: string) => void;
}

export const useRatingDialog = ({
  onClose,
}: IUseRatingDialogProps): IUseRatingDialogReturn => {
  const { t } = useTranslation('common');
  const [rate, setRate] = useState<number>();
  const [comment, setComment] = useState('');
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = (): void => {
    setRate(undefined);
    setComment('');
    setIsCommentValid(true);
    onClose();
  };

  const onCommentChange = (value: string): void => {
    setComment(value);
    setIsCommentValid(Boolean(value));
  };

  const saveRating = async (): Promise<void> => {
    if (!rate) {
      notify({
        text: t('select_rating'),
        type: ToastType.ERROR,
      });

      return;
    }

    if (!comment.trim()) {
      setIsCommentValid(false);
      notify({
        text: t('explain_rating'),
        type: ToastType.ERROR,
      });

      return;
    }

    setIsLoading(true);
    try {
      await postAccountManagerRate({
        rate,
        comment,
      });

      notify({
        text: t('rating_saved'),
        type: ToastType.SUCCESS,
      });

      onCancel();
    } catch (error) {
      const message = getApiErrorMessage(error);

      console.error('useRatingDialog.saveRating', error, message);
      notify({
        text: message,
        type: ToastType.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    saveRating,
    onCancel,
    rate,
    onRateChange: setRate,
    comment,
    onCommentChange,
    isCommentValid,
  };
};
