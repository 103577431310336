import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContentLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/ContentLoadingAnimation';
import { getChartLoaderBarsHeight } from 'components/Dashboard/helpers/getChartLoaderBarsHeight';

const BAR_COUNT = 7;
const BAR_GAP_PERCENT = 9;
const X_AXIS_LABEL_GAP_PERCENT = 5;

const barList = getChartLoaderBarsHeight(BAR_COUNT);
const X_AXIS_LABELS = new Array(BAR_COUNT).fill(undefined);

const getWidthPercent = (gapPercent: number): string =>
  `${100 / BAR_COUNT - gapPercent}%`;

const getXPercentByIndex = (
  index: number,
  gapPercent: number,
): string => `${(100 / BAR_COUNT) * index + gapPercent / 2}%`;

export const WeekChartPreviewLoadingAnimation: React.FC = () => {
  const contentLoaderProps = {
    width: '100%',
    height: '100%',
    viewBox: '0 0 100 100',
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={rootSx}
    >
      <Box sx={chartContainerSx}>
        <ContentLoadingAnimation {...contentLoaderProps}>
          {barList.map((bar, index) => {
            const width = getWidthPercent(BAR_GAP_PERCENT);
            const x = getXPercentByIndex(index, BAR_GAP_PERCENT);
            const y = `${100 - bar.heightPercent}%`;

            return (
              <rect
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                rx="1"
                ry="1"
                x={x}
                y={y}
                width={width}
                height={`${bar.heightPercent}%`}
              />
            );
          })}
        </ContentLoadingAnimation>
      </Box>
      <Box sx={xAxisContainerSx}>
        <ContentLoadingAnimation {...contentLoaderProps}>
          {X_AXIS_LABELS.map((_, index) => {
            const width = getWidthPercent(X_AXIS_LABEL_GAP_PERCENT);
            const x = getXPercentByIndex(
              index,
              X_AXIS_LABEL_GAP_PERCENT,
            );

            return (
              <rect
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                rx="1"
                ry="1"
                x={x}
                y={0}
                width={width}
                height="100%"
              />
            );
          })}
        </ContentLoadingAnimation>
      </Box>
    </Grid>
  );
};

const rootSx = {
  height: '100%',
  paddingBottom: 1,
};

const chartContainerSx = {
  height: '80%',
};

const xAxisContainerSx = {
  height: '14%',
};
