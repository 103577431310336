import React from 'react';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';

const AdvertiserMobileWelcomePage = React.lazy(() =>
  import(
    'components/WelcomePage/components/AdvertiserMobileWelcomePage'
  ).then((module) => ({
    default: module.AdvertiserMobileWelcomePage,
  })),
);
const AdvertiserWelcomePage = React.lazy(() =>
  import(
    'components/WelcomePage/components/AdvertiserWelcomePage'
  ).then((module) => ({
    default: module.AdvertiserWelcomePage,
  })),
);

const PublisherWelcomePage = React.lazy(() =>
  import(
    'components/WelcomePage/components/PublisherWelcomePage'
  ).then((module) => ({
    default: module.PublisherWelcomePage,
  })),
);

const PublisherMobileWelcomePage = React.lazy(() =>
  import(
    'components/WelcomePage/components/PublisherMobileWelcomePage'
  ).then((module) => ({
    default: module.PublisherMobileWelcomePage,
  })),
);

interface IWelcomePageProps {
  isAdvertiser?: boolean;
}

export const WelcomePage: React.FC<IWelcomePageProps> = ({
  isAdvertiser,
}) => {
  const { isMobile } = useScreenSize();

  if (isAdvertiser) {
    if (isMobile) {
      return <AdvertiserMobileWelcomePage />;
    }

    return <AdvertiserWelcomePage />;
  }

  if (isMobile) {
    return <PublisherMobileWelcomePage />;
  }

  return <PublisherWelcomePage />;
};
