import { useNews } from 'stars-hooks/lib/hooks/api/useNews';
import { parseNewsContent } from 'components/NewsFeed/helpers/parseNewsContent';
import { INews } from 'components/NewsFeed/types';
import { getMediaFilePath } from 'helpers/getMediaFilePath';

export const useParsedNews = (): ReadonlyArray<INews> => {
  const { data } = useNews({
    active: true,
    size: 10,
  });

  return (
    data?.response.map((item) => {
      const { text, link } = parseNewsContent(item.content);

      return {
        title: item.title,
        imageSrc: getMediaFilePath(item.image_url),
        content: {
          text,
          link,
        },
      };
    }) ?? []
  );
};
