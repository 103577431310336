import { AccountManagerStatus } from 'stars-api/lib/types/users/accountManagerStatus';

export const MANAGER_STATUS_DICTIONARY: Record<
  AccountManagerStatus,
  string
> = {
  [AccountManagerStatus.ON_VACATION]: 'ON VACATION',
  [AccountManagerStatus.OUT_OF_OFFICE]: 'OUT OF OFFICE',
  [AccountManagerStatus.ON_DUTY]: 'ON DUTY',
};
