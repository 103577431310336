import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { PublisherStatisticsRow } from 'components/Dashboard/components/PublisherStatisticsTile/components/PublisherStatisticsRow';

interface IPublisherStatisticsTileProps {
  icon: ReactNode;
  title: ReactNode;
  turnover?: number;
  earnings?: number;
  revenue?: number;
  turnoverRatio?: number;
  earningsRatio?: number;
  revenueRatio?: number;
  isLoading: boolean;
  isLoadingRatio?: boolean;
}

export const PublisherStatisticsTile: React.FC<
  IPublisherStatisticsTileProps
> = ({
  icon,
  title,
  turnover,
  earnings,
  revenue,
  isLoading,
  turnoverRatio,
  earningsRatio,
  revenueRatio,
  isLoadingRatio,
}) => {
  const { t } = useTranslation('common');

  return (
    <Paper sx={rootSx}>
      <Grid container height="100%" wrap="nowrap">
        <Grid
          item
          container
          sx={iconWrapSx}
          justifyContent="center"
          alignItems="center"
        >
          {icon}
        </Grid>
        <Grid item xs>
          <Typography mb={2} variant="h6">
            {title}
          </Typography>
          <PublisherStatisticsRow
            title={t('Turnover')}
            value={turnover}
            ratio={turnoverRatio}
            isLoading={isLoading}
            isLoadingRatio={isLoadingRatio}
          />
          <PublisherStatisticsRow
            title={t('Earnings')}
            value={earnings}
            ratio={earningsRatio}
            isLoading={isLoading}
            isLoadingRatio={isLoadingRatio}
          />
          <PublisherStatisticsRow
            title={t('Revenue')}
            value={revenue}
            ratio={revenueRatio}
            isLoading={isLoading}
            isLoadingRatio={isLoadingRatio}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const rootSx: SxProps<Theme> = ({ shape, breakpoints }) => ({
  paddingTop: 3,
  paddingRight: 3.5,
  paddingBottom: 3,
  paddingLeft: 3.5,
  height: '158px',
  maxHeight: '158px',
  boxSizing: 'border-box',
  boxShadow: shape.boxShadow,
  borderRadius: shape.borderRadiusLarge,

  [breakpoints.down('md')]: {
    paddingRight: 1,
    paddingLeft: 1,
  },

  [breakpoints.down('sm')]: {
    minHeight: '142px',
    height: '142px',
    paddingTop: 1.5,
    paddingBottom: 1.5,
  },
});

const iconWrapSx: SxProps<Theme> = ({ breakpoints }) => ({
  marginRight: 2,
  width: '40px',
  minWidth: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: 'secondary.main',

  [breakpoints.down('sm')]: {
    marginRight: 1,
  },
});
