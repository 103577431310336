import { IParsedNewsContent } from 'components/NewsFeed/types';

export const parseNewsContent = (
  html: string,
): IParsedNewsContent => {
  const domParser = new window.DOMParser();
  const document = domParser.parseFromString(html, 'text/html');
  const { body } = document;

  const link = body.querySelector('a');

  link?.remove();

  return {
    text: body.innerHTML,
    link: link?.outerHTML,
  };
};
