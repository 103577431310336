import Image from 'next/image';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import defaultAvatar from 'components/Dashboard/components/ManagerCard/assets/images/avatar.png';
import { getMediaFilePath } from 'helpers/getMediaFilePath';

interface IManagerPhotoProps {
  avatar?: string;
}

export const ManagerPhoto: React.FC<IManagerPhotoProps> = ({
  avatar,
}) => {
  const [imgSrc, setImgSrc] = useState(
    avatar ? getMediaFilePath(avatar) : defaultAvatar,
  );

  return (
    <Grid item sx={imageContainerSx} mr={1.5}>
      <Image
        width={76}
        height={76}
        src={imgSrc}
        alt="Your manager"
        onError={(): void => {
          setImgSrc(defaultAvatar);
        }}
      />
    </Grid>
  );
};

const imageContainerSx: SxProps = {
  backgroundColor: 'secondary.main',
  borderRadius: 4.5,
  width: 76,
  height: 76,

  '& img': {
    borderRadius: 4.5,
    objectFit: 'cover',
  },
};
