import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { IAmountLabelProps } from 'stars-components/lib/components/dashboard/AmountLabel';
import { useAdvertiserCustomReportByDay } from 'stars-hooks/lib/hooks/api/statistics/useAdvertiserCustomReportByDay';
import { useAmountProps } from 'components/Dashboard/hooks/useAmountProps';
import { useManagerId } from 'components/Dashboard/hooks/useManagerId';
import { useStatisticsUserId } from 'components/Dashboard/hooks/useStatisticsUserId';
import { Metric } from 'components/Dashboard/types/metric';

interface IUseAdvertiserProps {
  dateRange: INonEmptyDateRange;
  metric: Metric;
}

export const useAdvertiserAmountProps = ({
  dateRange,
  metric,
}: IUseAdvertiserProps): IAmountLabelProps => {
  const managerId = useManagerId();

  const userId = useStatisticsUserId();
  const { data, error } = useAdvertiserCustomReportByDay({
    dateFrom: dateRange[0].toDate(),
    dateTo: dateRange[1].toDate(),
    userId,
    managerId,
  });

  const isLoading = !data && !error;
  const amountProps = useAmountProps({ data, metric, dateRange });

  return {
    ...amountProps,
    isLoading,
  };
};
