import { ICustomReportByDayResponseModel } from 'stars-api/lib/types/statistics/customReportByDayResponseModel';
import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { Metric } from 'components/Dashboard/types/metric';

interface ICalculateMetricParams {
  metric: Metric;
  data?: ICustomReportByDayResponseModel;
  dateRange: INonEmptyDateRange;
}

export const calculateTotalWithinDateRange = ({
  dateRange,
  data,
  metric,
}: ICalculateMetricParams): number => {
  const [dateFrom, dateTo] = dateRange;

  return (
    data?.reduce((acc, item) => {
      const shouldCountMetric =
        (dateFrom.isSame(item.day, 'day') ||
          dateFrom.isBefore(item.day, 'day')) &&
        (dateTo.isSame(item.day, 'day') ||
          dateTo.isAfter(item.day, 'day'));

      return shouldCountMetric ? acc + item[metric] : acc;
    }, 0) || 0
  );
};
