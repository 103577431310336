import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';
import { AccountManagerStatus } from 'stars-api/lib/types/users/accountManagerStatus';
import { MANAGER_STATUS_DICTIONARY } from 'components/Dashboard/components/ManagerCard/constants/managerStatusDictionary';

interface IManagerStatusProps {
  status: AccountManagerStatus;
}

export const ManagerStatus: React.FC<IManagerStatusProps> = ({
  status,
}) => {
  const isOnVacation = status === AccountManagerStatus.ON_VACATION;
  const isOutOfOffice = status === AccountManagerStatus.OUT_OF_OFFICE;

  return (
    <Grid
      item
      container
      sx={[
        statusSx,
        {
          backgroundColor: isOnVacation
            ? 'warning.light'
            : isOutOfOffice
            ? 'error.light'
            : 'success.light',
        },
      ]}
      mt={0.5}
      flexWrap="nowrap"
      alignItems="center"
    >
      <Box
        sx={[
          statusDotSx,
          {
            backgroundColor: isOnVacation
              ? 'warning.main'
              : isOutOfOffice
              ? 'error.main'
              : 'success.main',
          },
        ]}
      />
      <Typography
        sx={[
          statusTextSx,
          {
            color: isOnVacation
              ? 'warning.main'
              : isOutOfOffice
              ? 'error.main'
              : 'success.main',
          },
        ]}
      >
        {MANAGER_STATUS_DICTIONARY[status]}
      </Typography>
    </Grid>
  );
};

const statusSx: SystemStyleObject = {
  py: 0,
  pr: 1,
  pl: 0.5,
  borderRadius: 1,
  width: 'fit-content',
  height: 20,
};

const statusDotSx: SystemStyleObject = {
  width: 8,
  height: 8,
  mr: 0.5,
  borderRadius: '50%',
};

const statusTextSx: SystemStyleObject = {
  fontSize: 13,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  color: 'success.main',
};
