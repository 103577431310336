import { useTranslation } from 'next-i18next';
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface IDashboardChartPlaceholderProps {
  text?: string;
}

export const DashboardChartPlaceholder: React.FC<
  IDashboardChartPlaceholderProps
> = ({ text }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Box sx={rowSx} />
      <Box sx={rowSx} />
      <Grid
        sx={rowSx}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Typography sx={titleSx}>
          {text ||
            t('There are no active campaigns in the selected period')}
        </Typography>
      </Grid>
      <Box sx={rowSx} />
      <Box sx={rowSx} />
    </>
  );
};

const rowSx = {
  height: '20%',
  borderTop: '1px solid',
  borderTopColor: 'grey.200',
  boxSizing: 'border-box',

  '&:last-child': {
    borderBottom: '1px solid',
    borderBottomColor: 'grey.200',
  },
};

const titleSx = {
  color: 'grey.600',
};
