import React, {
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { CURRENT_DATE_RANGE } from 'components/Dashboard/constants/dates';
import { Metric } from 'components/Dashboard/types/metric';

interface IDashboardContext {
  barMetric: Metric;
  lineMetric: Metric;
  dateRange: INonEmptyDateRange;
  setBarMetric: React.Dispatch<SetStateAction<Metric>>;
  setLineMetric: React.Dispatch<SetStateAction<Metric>>;
  setDateRange: React.Dispatch<SetStateAction<INonEmptyDateRange>>;
}

const DashboardContext = React.createContext<IDashboardContext>({
  barMetric: Metric.IMPRESSIONS,
  lineMetric: Metric.AMOUNT,
  dateRange: CURRENT_DATE_RANGE,
  setBarMetric: () => undefined,
  setLineMetric: () => undefined,
  setDateRange: () => undefined,
});

export const DashboardProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [barMetric, setBarMetric] = useState(Metric.IMPRESSIONS);
  const [lineMetric, setLineMetric] = useState(Metric.AMOUNT);
  const [dateRange, setDateRange] = useState(CURRENT_DATE_RANGE);

  const context = {
    barMetric,
    lineMetric,
    dateRange,
    setBarMetric,
    setLineMetric,
    setDateRange,
  };

  return (
    <DashboardContext.Provider value={context}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = (): IDashboardContext => {
  return useContext(DashboardContext);
};
