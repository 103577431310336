import React from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ContentLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/ContentLoadingAnimation';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { MOBILE_WIDTH } from 'constants/sizes';

const HEIGHT = 528;

export const NewsFeedLoadingAnimation: React.FC = () => {
  const { isMobile } = useScreenSize();

  return (
    <Box
      flexDirection="column"
      display="flex"
      width="100%"
      height="100%"
      sx={rootSx}
    >
      <ContentLoadingAnimation
        height={HEIGHT}
        width={isMobile ? MOBILE_WIDTH : '100%'}
      >
        <rect x="8" y="30" width="50%" height="16" rx="1" ry="1" />
        <rect x="8" y="69" width="70%" height="24" rx="1" ry="1" />
        <rect x="8" y="110" width="60%" height="16" rx="1" ry="1" />
        <rect x="8" y="138" width="30%" height="16" rx="1" ry="1" />
        <rect x="8" y="165" width="40%" height="16" rx="1" ry="1" />
        <rect x="0" y="55%" width="100%" height="45%" />
      </ContentLoadingAnimation>
    </Box>
  );
};

const rootSx: SxProps<Theme> = ({ shape }) => ({
  backgroundColor: 'common.white',
  borderRadius: shape.borderRadiusLarge,
  boxShadow: shape.boxShadow,
});
