import { useTranslation } from 'next-i18next';
import React from 'react';
import { TextField, dialogClasses } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps, SystemStyleObject } from '@mui/system';
import { ConfirmDialog } from 'stars-components/lib/components/ConfirmDialog';
import { RatingScale } from 'components/Dashboard/components/ManagerCard/components/rating/RatingScale';
import { useRatingDialog } from 'components/Dashboard/components/ManagerCard/hooks/useRatingDialog';

interface IRatingDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RatingDialog: React.FC<IRatingDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('common');
  const {
    isLoading,
    saveRating,
    onCancel,
    rate,
    onRateChange,
    onCommentChange,
    comment,
    isCommentValid,
  } = useRatingDialog({
    onClose,
  });

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title={t('rate_manager')}
      sx={dialogSx}
      onClose={onCancel}
      text={
        <Grid container direction="column">
          <RatingScale rate={rate} onChange={onRateChange} />
          <Grid
            item
            container
            justifyContent="space-between"
            flexWrap="nowrap"
            width="auto"
            mt={1.5}
          >
            <Grid item>
              <Typography fontSize={13} color="grey.600" sx={textSx}>
                {t('extremely_dissatisfied')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontSize={13}
                color="grey.600"
                sx={textSx}
                textAlign="right"
              >
                {t('exceptional_satisfied')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item mt={3}>
            <TextField
              error={!isCommentValid}
              fullWidth
              variant="standard"
              label={`${t('explain_rating')}*`}
              value={comment}
              onChange={({ target }): void =>
                onCommentChange(target.value)
              }
            />
          </Grid>
        </Grid>
      }
      actionButtons={[
        <Button
          key="cancelBtn"
          color="primary"
          onClick={onCancel}
          sx={buttonSx}
          disabled={isLoading}
        >
          {t('cancel')}
        </Button>,
        <Button
          key="confirmBtn"
          variant="contained"
          color="primary"
          onClick={saveRating}
          sx={buttonSx}
          disabled={isLoading}
        >
          {t('rate')}
        </Button>,
      ]}
    />
  );
};

const buttonSx: SxProps = {
  textTransform: 'none',
};

const dialogSx = ({ breakpoints }: Theme): SystemStyleObject => ({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 473,

    [breakpoints.down('sm')]: {
      margin: 3,
    },
  },
});

const textSx: SxProps<Theme> = ({ breakpoints }) => ({
  [breakpoints.down(520)]: {
    width: 'min-content',
    color: 'text.primary',
  },
});
