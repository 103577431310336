import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { IContacts } from 'stars-api/lib/types/users/accountManagerResponseModel';
import { openIntercomChat } from 'stars-utils/lib/helpers/openIntercomChat';
import { Contacts } from 'components/Dashboard/components/ManagerCard/components/Contacts';

interface IContactsProps extends IContacts {
  email: string;
  statusComment?: string;
}

export const NotOnDuty: React.FC<IContactsProps> = ({
  email,
  skype,
  telegram,
  statusComment,
}) => {
  const { t } = useTranslation('common');
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <Grid item container alignItems="center" sx={infoSx}>
        <Typography variant="subtitle1" lineHeight="inherit">
          <Trans
            i18nKey="contact_us"
            components={[
              <Link
                key="ContactLink"
                onClick={openIntercomChat}
                sx={{ cursor: 'pointer' }}
              />,
            ]}
          >
            Contact support chat for urgent questions.
          </Trans>
        </Typography>
        <Typography variant="subtitle1" lineHeight="inherit">
          {statusComment}
        </Typography>
      </Grid>
      <Accordion expanded={isExpanded} sx={accordionSx}>
        <AccordionSummary
          onClick={(): void => setIsExpanded(!isExpanded)}
          sx={accordionSummarySx}
          expandIcon={
            <ArrowDropUpIcon fontSize="inherit" color="primary" />
          }
        >
          <Typography color="primary" fontWeight={500}>
            {isExpanded ? t('hide_contacts') : t('show_contacts')}
          </Typography>
        </AccordionSummary>
        <Divider sx={dividerSx} />
        <AccordionDetails sx={accordionDetailsSx}>
          <Contacts email={email} skype={skype} telegram={telegram} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const accordionSx: SxProps = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  borderRadius: '12px !important',
  backgroundColor: 'secondary.light',
  boxShadow: 'none',

  '&:before': {
    opacity: 0,
  },
  '& .MuiButtonBase-root': {
    minHeight: '48px !important',
  },
};

const accordionSummarySx: SxProps = {
  [`& .${accordionSummaryClasses.content}`]: {
    margin: '0 !important',
    justifyContent: 'center',
    flexGrow: 0,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    fontSize: 24,
  },
};

const accordionDetailsSx: SxProps = {
  px: 3.5,
  pt: 1.5,
};

const dividerSx: SxProps = {
  mx: 3.5,
};

const infoSx: SxProps = {
  borderRadius: 1.5,
  border: 'solid 1px #e3e3e3',
  px: 1.5,
  py: 0.5,
  mb: 6,
  lineHeight: 1.23,
};
