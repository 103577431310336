import { useTranslation } from 'next-i18next';
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { AmountLabel } from 'stars-components/lib/components/dashboard/AmountLabel';
import { LinearLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/LinearLoadingAnimation';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { formatRatio } from 'components/Dashboard/components/PublisherStatisticsTile/helpers/formatRatio';

interface IPublisherStatisticsRowProps {
  title: string;
  value?: number;
  ratio?: number;
  isLoading: boolean;
  isLoadingRatio?: boolean;
}

export const PublisherStatisticsRow: React.FC<
  IPublisherStatisticsRowProps
> = ({ title, value, isLoading, ratio, isLoadingRatio }) => {
  const { t } = useTranslation('common');
  const planIsCompleted = Number(ratio) >= 1;
  const shouldShowRatio = typeof ratio !== 'undefined';

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      mt={0.5}
    >
      <Grid item container alignItems="center" xs>
        <Typography sx={titleSx} variant="subtitle1">
          {title}
        </Typography>
        {isLoadingRatio ? (
          <LinearLoadingAnimation height={20} width={46} />
        ) : (
          shouldShowRatio && (
            <Tooltip title={t('Plan implementation') || ''}>
              <Box
                sx={[
                  ratioSx,
                  planIsCompleted
                    ? planCompletedSx
                    : planProgressedSx,
                ]}
              >
                <Typography variant="subtitle1">
                  {formatRatio(ratio || 0)}
                </Typography>
              </Box>
            </Tooltip>
          )
        )}
      </Grid>
      <Grid item>
        <Tooltip title={value ? formatCurrency(value) : ''}>
          <div>
            <AmountLabel
              value={value?.toFixed(2)}
              unit="USD"
              isShort
              isLoading={isLoading}
              size="small"
            />
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const titleSx = {
  color: 'grey.600',
  minWidth: '60px',
};

const ratioSx = ({ shape }: Theme): SystemStyleObject<Theme> => ({
  cursor: 'pointer',
  borderRadius: shape.borderRadiusSmall,
  paddingRight: 0.75,
  paddingLeft: 0.75,
});

const planCompletedSx = {
  backgroundColor: 'success.light',
  color: 'success.main',
};

const planProgressedSx = {
  backgroundColor: 'secondary.light',
  color: 'grey.600',
};
