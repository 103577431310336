import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { IAmountLabelProps } from 'stars-components/lib/components/dashboard/AmountLabel';
import { usePublisherAdvancedCustomReportByDay } from 'stars-hooks/lib/hooks/api/statistics/usePublisherAdvancedCustomReportByDay';
import { useAmountProps } from 'components/Dashboard/hooks/useAmountProps';
import { useDashboardUserId } from 'components/Dashboard/hooks/useDashboardUserId';
import { Metric } from 'components/Dashboard/types/metric';

interface IUsePublisherAmountProps {
  dateRange: INonEmptyDateRange;
  metric: Metric;
}

export const usePublisherAdvancedAmountProps = ({
  dateRange,
  metric,
}: IUsePublisherAmountProps): IAmountLabelProps => {
  const userId = useDashboardUserId();
  const { data, error } = usePublisherAdvancedCustomReportByDay({
    dateFrom: dateRange[0].toDate(),
    dateTo: dateRange[1].toDate(),
    userId,
  });

  const isLoading = !data && !error;

  const amountProps = useAmountProps({ data, metric, dateRange });

  return {
    ...amountProps,
    isLoading,
  };
};
