import { Dayjs } from 'dayjs';
import { usePublisherCustomReportByDay } from 'stars-hooks/lib/hooks/api/statistics/usePublisherCustomReportByDay';
import {
  IChartDataMapper,
  createChartData,
} from 'components/Dashboard/helpers/createChartData';
import { useManagerId } from 'components/Dashboard/hooks/useManagerId';
import { useStatisticsUserId } from 'components/Dashboard/hooks/useStatisticsUserId';

interface IUsePublisherChartDataParams<T> {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  mapper: IChartDataMapper<T>;
}

interface IUsePublisherChartDataReturn<T> {
  data?: T[];
  isError: boolean;
}

export const usePublisherChartData = <T>(
  params: IUsePublisherChartDataParams<T>,
): IUsePublisherChartDataReturn<T> => {
  const { dateFrom, dateTo } = params;
  const managerId = useManagerId();

  const userId = useStatisticsUserId();
  const { data: metricsData, error } = usePublisherCustomReportByDay(
    {
      dateFrom: dateFrom.toDate(),
      dateTo: dateTo.toDate(),
      userId,
      managerId,
    },
    { shouldShowError: true },
  );

  const isLoading = !metricsData && !error;

  return {
    data: isLoading
      ? undefined
      : createChartData({
          ...params,
          metricsData,
        }),
    isError: Boolean(error),
  };
};
