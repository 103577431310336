import { Theme } from '@mui/material/styles';
import { SxProps, SystemStyleObject } from '@mui/system';

const PADDING_X = 3.5;
const PADDING_X_MOBILE = 1.5;

export const slideBtnSx = ({
  breakpoints,
}: Theme): SystemStyleObject<Theme> => ({
  display: 'none',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50%',
  transform: 'translate(0, -50%)',
  height: 36,
  width: 36,
  minWidth: 36,
  borderRadius: '50%',
  backgroundColor: 'common.white',
  opacity: 0.7,
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  transition: 'ease .2s',

  '&:hover': {
    opacity: 1,
    transition: 'ease .2s',
  },

  [breakpoints.down('md')]: {
    display: 'flex',
  },
});

export const nextSlideBtnSx = ({
  spacing,
}: Theme): SystemStyleObject<Theme> => ({
  right: spacing(1.5),
});

export const prevSlideBtnSx = ({
  spacing,
}: Theme): SystemStyleObject<Theme> => ({
  left: spacing(1.5),
});

export const rootSx: SxProps<Theme> = ({ shape, breakpoints }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: 3,
  paddingRight: PADDING_X,
  paddingLeft: PADDING_X,
  overflow: 'auto',
  height: '528px',
  boxSizing: 'border-box',
  borderRadius: shape.borderRadiusLarge,
  boxShadow: shape.boxShadow,
  flexGrow: 1,

  '&:hover': {
    '& .MuiButton-root': {
      display: 'flex',
    },
  },

  [breakpoints.down('sm')]: {
    paddingRight: PADDING_X_MOBILE,
    paddingLeft: PADDING_X_MOBILE,
  },

  [breakpoints.down('xs')]: {
    height: '488px',
  },
});

export const blockTitleSx: SxProps<Theme> = ({ breakpoints }) => ({
  marginBottom: 1.5,
  [breakpoints.down('xs')]: {
    fontSize: '1.125rem',
  },
});

export const newsTitleSx: SxProps<Theme> = ({ breakpoints }) => ({
  marginBottom: 1.5,
  fontWeight: 500,
  fontSize: 34,
  overflow: 'hidden',
  display: '-webkit-box',
  ':-webkit-line-clamp': 2,
  ':-webkit-box-orient': 'vertical',

  [breakpoints.down('xs')]: {
    fontSize: '1.325rem',
  },
});

export const imgContainerSx: SxProps<Theme> = ({
  breakpoints,
  spacing,
}) => ({
  position: 'relative',
  marginRight: -PADDING_X,
  marginLeft: -PADDING_X,
  width: `calc(100% + ${Number(spacing(PADDING_X)) * 2}px)`,
  height: '216px',

  [breakpoints.down('sm')]: {
    marginRight: -PADDING_X_MOBILE,
    marginLeft: -PADDING_X_MOBILE,
    width: `calc(100% + ${Number(spacing(PADDING_X_MOBILE)) * 2}px)`,
  },
});

export const imgSx = {
  position: 'absolute',
  inset: 0,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
};
export const activeImgSx = {
  opacity: 1,
  transition: 'opacity 0.3s ease-in-out',
};
