import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DashboardSelect } from 'components/Dashboard/components/DashboardSelect';
import { useMetricLabelByType } from 'components/Dashboard/hooks/useMetricLabelByType';
import { Metric } from 'components/Dashboard/types/metric';

interface IDashboardMetricSelectProps {
  value: Metric;
  chartColor: string;
  hiddenMetrics?: ReadonlyArray<Metric>;
  onChange(metric: Metric): void;
}

export const DashboardMetricSelect: React.FC<
  IDashboardMetricSelectProps
> = ({ value, chartColor, hiddenMetrics = [], onChange }) => {
  const labelByMetric = useMetricLabelByType();

  const metrics = Object.values(Metric)
    .filter((metric) => !hiddenMetrics.includes(metric))
    .map((metric) => ({
      value: metric,
      label: labelByMetric[metric],
    }));

  return (
    <Grid container alignItems="center">
      <Box sx={[indicatorSx, { background: chartColor }]} />
      <DashboardSelect
        value={value}
        options={metrics}
        onChange={onChange}
      />
    </Grid>
  );
};

const indicatorSx = {
  width: '8px',
  height: '8px',
  marginRight: 1,
  borderRadius: '50%',
};
