import { Dayjs } from 'dayjs';
import { usePublisherAdvancedCustomReportByDay } from 'stars-hooks/lib/hooks/api/statistics/usePublisherAdvancedCustomReportByDay';
import { useDashboardUserId } from 'components/Dashboard/hooks/useDashboardUserId';

interface IUseTotalStatisticsParams {
  dateFrom: Dayjs;
  dateTo: Dayjs;
}

export interface IUseTotalStatistics {
  turnover?: number;
  earnings?: number;
  revenue?: number;
  isLoading: boolean;
}

export const useTotalPublisherStatistics = ({
  dateFrom,
  dateTo,
}: IUseTotalStatisticsParams): IUseTotalStatistics => {
  const userId = useDashboardUserId();

  const { data, error } = usePublisherAdvancedCustomReportByDay({
    userId,
    dateFrom: dateFrom.toDate(),
    dateTo: dateTo.toDate(),
  });

  const isLoading = !data && !error;

  const turnover = data?.reduce((acc, item) => acc + item.amount, 0);
  const earnings = data?.reduce((acc, item) => acc + item.revenue, 0);
  const revenue = data?.reduce(
    (acc, item) => acc + item.net_revenue,
    0,
  );

  return {
    turnover,
    earnings,
    revenue,
    isLoading,
  };
};
