import React from 'react';
import { accessControl } from 'stars-access-control';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { AdvertiserDashboard } from 'components/Dashboard/components/AdvertiserDashboard';
import { DspDashboard } from 'components/Dashboard/components/DspDashboard';
import { PublisherAdvancedDashboard } from 'components/Dashboard/components/PublisherAdvancedDashboard';
import { PublisherDashboard } from 'components/Dashboard/components/PublisherDashboard';
import { SspDashboard } from 'components/Dashboard/components/SspDashboard';

interface IDashboardProps {
  isAdvertiser?: boolean;
  isPublisher?: boolean;
}

export const Dashboard: React.FC<IDashboardProps> = ({
  isAdvertiser,
  isPublisher,
}) => {
  const { isSuperUser } = useUserDataContext();

  if (isSuperUser) {
    if (isAdvertiser) {
      return <AdvertiserDashboard />;
    }

    if (isPublisher) {
      return <PublisherAdvancedDashboard />;
    }
  }

  // Проверка на isRtbDsp и isRtbSsp должна всегда быть выше, чем проверка на isAdvertiser и isPublisher
  // т. к. роль isRtbDsp приходит вместе с isAdvertiser, а isRtbSsp вместе с isPublisher
  if (accessControl.role.isRtbDsp()) {
    return <DspDashboard />;
  }

  if (accessControl.role.isRtbSsp()) {
    return <SspDashboard />;
  }

  if (isAdvertiser) {
    return <AdvertiserDashboard />;
  }

  return <PublisherDashboard />;
};
