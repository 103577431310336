import React from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { INDICATOR_INTERVAL_IN_MS } from 'components/NewsFeed/constants';

interface INewsTimeIndicatorProps {
  index: number;
  activeNewsIndex: number;
  indicatorWidthPercent: string;
  indicatorClickHandler(index: number): void;
}

export const NewsTimeIndicator: React.FC<INewsTimeIndicatorProps> = ({
  index,
  activeNewsIndex,
  indicatorWidthPercent,
  indicatorClickHandler,
}) => {
  const isActive = index === activeNewsIndex;
  const onClick = (): void => {
    if (!isActive) {
      indicatorClickHandler(index);
    }
  };

  return (
    <Box
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
      data-test={`newsTimeIndicator_${index}`}
      sx={indicatorWrapSx}
    >
      <Box
        height="100%"
        sx={[
          isActive && activeIndicatorSx,
          { width: isActive ? indicatorWidthPercent : '0px' },
        ]}
      />
    </Box>
  );
};

const activeIndicatorSx = {
  backgroundColor: 'primary.main',
  transition: `width ${INDICATOR_INTERVAL_IN_MS}ms linear`,
};

const indicatorWrapSx: SxProps<Theme> = ({ breakpoints }) => ({
  width: '100%',
  maxWidth: '80px',
  height: '4px',
  borderRadius: '3px',
  background: '#f1f2f7',
  overflow: 'hidden',
  cursor: 'pointer',

  '&:not(:last-child)': {
    marginRight: 2,
  },

  [breakpoints.down('xs')]: {
    maxWidth: '60px',
  },
});
