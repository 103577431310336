import { useTranslation } from 'next-i18next';
import React from 'react';
import Filter3Icon from '@mui/icons-material/Filter3';
import dayjs from 'dayjs';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { formatDate } from 'stars-utils/lib/helpers/dates/formatDate';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { MonthChartPreview } from 'components/Dashboard/components/MonthChartPreview';
import { DATE_PREVIEW_CHART_LABEL_FORMAT } from 'components/Dashboard/constants/dates';
import { useAdvertiserChartData } from 'components/Dashboard/hooks/useAdvertiserChartData';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';
import { Metric } from 'components/Dashboard/types/metric';

const COUNT_MONTHS_IN_QUARTER = 3;

export const SpentThisQuarter: React.FC = () => {
  const { t } = useTranslation('common');

  const today = dayjs();
  const isFirstMonthOfQuarter = !(
    today.month() % COUNT_MONTHS_IN_QUARTER
  );
  const isTodayFirstDayOfMonth = today.date() === 1;
  const shouldShowChart =
    !isTodayFirstDayOfMonth || !isFirstMonthOfQuarter;

  const { data: chartData } = useAdvertiserChartData({
    dateFrom: today.startOf('quarter'),
    dateTo: today,
    mapper: (date, metric): IPreviewChartRecord => ({
      date: formatDate(date, DATE_PREVIEW_CHART_LABEL_FORMAT),
      value: metric?.[Metric.AMOUNT] || 0,
    }),
  });

  const amount = chartData?.reduce(
    (acc, item) => acc + item.value,
    0,
  );

  return (
    <AccountingWidget
      icon={<Filter3Icon color="primary" />}
      title={t('Clients spent this quarter')}
      amountProps={{
        value: amount && formatCurrency(amount),
        unit: 'USD',
        isLoading: !chartData,
      }}
      content={
        shouldShowChart && <MonthChartPreview data={chartData} />
      }
    />
  );
};
