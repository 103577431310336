import { useTranslation } from 'next-i18next';
import CodeIcon from '@mui/icons-material/Code';
import PaymentIcon from '@mui/icons-material/Payment';
import SecurityIcon from '@mui/icons-material/Security';
import WebIcon from '@mui/icons-material/Web';
import { useTheme } from '@mui/material';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { IGuideTileProps } from 'types/guideTiles';

export const usePublisherGuideTileList = (
  isRow?: boolean,
): ReadonlyArray<IGuideTileProps> => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();
  const { isTablet, isDesktop, isLargeDesktop } = useScreenSize();

  const tiles: ReadonlyArray<IGuideTileProps> = [
    {
      title: t('How do I add a website and ad spots'),
      text: t('Website monetization guide'),
      Icon: WebIcon,
      backgroundIcon: '#ff9e18',
      href: 'https://support.trafficstars.com/en/articles/1840446-how-do-i-add-a-website-and-ad-spots',
    },
    {
      title: t('Developers Knowledgebase'),
      text: t('Instructions and technical parameters'),
      backgroundIcon: palette.success.main,
      Icon: CodeIcon,
      href: 'https://support.trafficstars.com/en/articles/5224332-developers-knowledgebase',
    },
  ];

  if (isRow && (isTablet || (isDesktop && !isLargeDesktop))) {
    return tiles;
  }

  return [
    ...tiles,
    {
      title: t('Payment Methods and Terms for Publishers'),
      text: t('How do I get paid by TrafficStars'),
      backgroundIcon: '#7d62f4',
      Icon: PaymentIcon,
      href: 'https://support.trafficstars.com/en/articles/2069236-payment-methods-and-terms-for-publishers',
    },
    {
      title: t('Googles Misleading Ad Restrictions'),
      text: t('What to do to stay google compliant'),
      backgroundIcon: '#ff1547',
      Icon: SecurityIcon,
      href: 'https://support.trafficstars.com/en/articles/1840449-google-s-misleading-ad-restrictions-publisher-guide',
    },
  ];
};
