import { useTranslation } from 'next-i18next';
import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { IContacts } from 'stars-api/lib/types/users/accountManagerResponseModel';
import { getTelegramData } from 'components/Dashboard/components/ManagerCard/helpers/getTelegramData';

interface IContactsProps extends IContacts {
  email: string;
}

export const Contacts: React.FC<IContactsProps> = ({
  email,
  skype,
  telegram,
}) => {
  const { t } = useTranslation('common');
  const telegramData = getTelegramData(telegram);

  return (
    <Grid
      item
      spacing={1}
      container
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Grid
        item
        container
        flexWrap="nowrap"
        justifyContent="space-between"
      >
        <Grid item mr={1}>
          <Typography variant="subtitle1" color="grey.600">
            {t('email')}
          </Typography>
        </Grid>
        <Grid item>
          <Link
            target="_blank"
            underline="hover"
            href={`mailto:${email}`}
          >
            <Typography variant="body2" fontWeight={500}>
              {email}
            </Typography>
          </Link>
        </Grid>
      </Grid>
      {skype && (
        <Grid
          item
          container
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Grid item mr={1}>
            <Typography variant="subtitle1" color="grey.600">
              {t('skype')}
            </Typography>
          </Grid>
          <Grid item>
            <Link
              target="_blank"
              underline="hover"
              href={`skype:${skype}?chat`}
            >
              <Typography variant="body2" fontWeight={500}>
                {skype}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      )}
      {telegramData && (
        <Grid
          item
          container
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Grid item mr={1}>
            <Typography variant="subtitle1" color="grey.600">
              {t('telegram')}
            </Typography>
          </Grid>
          <Grid item>
            <Link
              underline="hover"
              target="_blank"
              href={telegramData.link}
            >
              <Typography variant="body2" fontWeight={500}>
                {telegramData.telegram}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
