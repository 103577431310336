import { useTranslation } from 'next-i18next';
import React from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { useEarnings } from 'stars-hooks/lib/hooks/api/users/useEarnings';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';

interface IUnpaidEarningsProps {
  title?: string;
}

export const UnpaidEarnings: React.FC<IUnpaidEarningsProps> = ({
  title,
}) => {
  const { t } = useTranslation('common');
  const { userId } = useUserDataContext();
  const { data, error } = useEarnings(Number(userId), {
    shouldFetch: Boolean(userId),
  });

  const isLoading = !data && !error;

  const { unpaid, total } = data || {};

  const accountingTitle = title || t('Unpaid earnings');
  const value = unpaid && formatCurrency(unpaid);
  const label =
    total && `${t('Total earnings')} ${formatCurrency(total)} USD`;

  return (
    <AccountingWidget
      icon={<MonetizationOnIcon color="primary" />}
      title={accountingTitle}
      amountProps={{
        value,
        isLoading,
        unit: 'USD',
      }}
      label={label}
    />
  );
};
