import { useTranslation } from 'next-i18next';
import React from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { AccountingWidget } from 'stars-components/lib/components/dashboard/AccountingWidget';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';

export const AccountBalance: React.FC = () => {
  const { t } = useTranslation('common');
  const { balance } = useUserDataContext();

  return (
    <AccountingWidget
      icon={<MonetizationOnIcon color="primary" />}
      title={t('Account Balance')}
      amountProps={{
        value: balance && formatCurrency(balance),
        unit: 'USD',
      }}
    />
  );
};
