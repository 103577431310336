import React from 'react';
import { GuideTiles } from 'components/GuideTiles';
import { useAdvertiserGuideTileList } from 'hooks/useAdvertiserGuideTileList';

interface IAdvertiserGuideTilesProps {
  direction?: 'column' | 'row';
}

export const AdvertiserGuideTiles: React.FC<
  IAdvertiserGuideTilesProps
> = ({ direction }) => {
  const isRow = direction === 'row';
  const guideTileList = useAdvertiserGuideTileList(isRow);

  return (
    <GuideTiles direction={direction} guideTileList={guideTileList} />
  );
};
