import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AccountManagerStatus } from 'stars-api/lib/types/users/accountManagerStatus';
import { ManagerStatus } from 'components/Dashboard/components/ManagerCard/components/ManagerStatus';

interface IManagerNameProps {
  name: string;
  position?: string;
  status: AccountManagerStatus;
}

export const ManagerName: React.FC<IManagerNameProps> = ({
  name,
  status,
  position,
}) => {
  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h5">{name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="grey.600">
          {position}
        </Typography>
      </Grid>
      <ManagerStatus status={status} />
    </Grid>
  );
};
