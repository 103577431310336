import React from 'react';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ManagerCard } from 'components/Dashboard/components/ManagerCard';
import { NewsFeed } from 'components/NewsFeed';

interface IDashboardLayoutProps {
  accountingContent: ReadonlyArray<React.ReactNode>;
  chartContent: React.ReactNode;
  guideContent: React.ReactNode;
}

export const DashboardLayout: React.FC<IDashboardLayoutProps> = ({
  accountingContent,
  chartContent,
  guideContent,
}) => {
  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      spacing={2}
      data-test="dashboardLayout"
    >
      <Grid container item spacing={2}>
        {accountingContent.map((accountingItem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item xs={12} md={4} key={index}>
            {accountingItem}
          </Grid>
        ))}
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} lg={8}>
          {chartContent}
        </Grid>
        <Grid item xs={12} lg={4}>
          <NewsFeed />
        </Grid>
      </Grid>
      <Grid item container spacing={2} sx={containerSx}>
        <Grid item xs={12} sm={6} lg={8}>
          {guideContent}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ManagerCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

const containerSx: SxProps<Theme> = ({ breakpoints }) => ({
  flexDirection: 'row',

  [breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
});
