interface IGetTelegramDataReturn {
  telegram: string;
  link: string;
}

export const getTelegramData = (
  nickname?: string,
): IGetTelegramDataReturn | undefined => {
  if (!nickname) {
    return;
  }

  //if nickname is a telegram link
  if (/^https?:\/\/t.me\/*/.test(nickname)) {
    return {
      telegram: `@${nickname.split('/').pop()}`,
      link: nickname,
    };
  }

  //removing all characters except letters, numbers, underscores
  const clearNickname = nickname.replace(/[^\w_]/g, '');

  //if only numbers
  if (/^\d+$/.test(clearNickname)) {
    return {
      telegram: `+${clearNickname}`,
      link: `https://t.me/+${clearNickname}`,
    };
  }

  return {
    telegram: `@${clearNickname}`,
    link: `https://t.me/${clearNickname}`,
  };
};
