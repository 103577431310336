import React from 'react';
import Grid from '@mui/material/Grid';
import { LinearLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/LinearLoadingAnimation';
import { GraphMaskIcon } from 'components/Dashboard/components/MonthChartPreview/components/GraphMaskIcon';

export const MonthChartPreviewLoadingAnimation: React.FC = () => {
  return (
    <>
      <GraphMaskIcon />
      <Grid lineHeight={0}>
        <Grid item>
          <LinearLoadingAnimation width="100%" height={56} />
        </Grid>
        <Grid item>
          <LinearLoadingAnimation width="100%" height={1} />
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          lineHeight={0}
          mt={0.75}
        >
          <LinearLoadingAnimation width={40} height={12} />
          <LinearLoadingAnimation width={40} height={12} />
        </Grid>
      </Grid>
    </>
  );
};
