import React from 'react';
import Box from '@mui/material/Box';
import { INews } from 'components/NewsFeed/types';

interface INewsFeedContentProps {
  news: INews;
}

export const NewsFeedContent: React.FC<INewsFeedContentProps> = ({
  news,
}) => {
  return (
    <Box sx={rootSx}>
      <Box
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: news?.content.text,
        }}
        sx={contentTextSx}
      />
      {news.content.link && (
        <Box
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: news.content.link,
          }}
          sx={contentLinkWrapSx}
        />
      )}
    </Box>
  );
};

const rootSx = {
  color: 'grey.600',
  overflow: 'hidden',
  fontFamily: 'fontFamily',
  lineHeight: 1.5,
  fontWeight: 400,
  letterSpacing: '0.00938em',
};

const contentTextSx = {
  fontSize: 18,
  overflow: 'hidden',
  display: '-webkit-box',
  ':-webkit-line-clamp': 2,
  ':-webkit-box-orient': 'vertical',

  '& > *': {
    display: 'inline',
  },

  '& *': {
    margin: 0,
    fontSize: 18,
  },
};

const contentLinkWrapSx = {
  '& a': {
    textDecoration: 'none',
    color: 'primary.main',
    fontSize: 18,
    fontWeight: 500,

    '& b,strong': {
      fontWeight: 500,
    },
  },
};
