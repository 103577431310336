import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { IGuideTileProps } from 'types/guideTiles';

export const MobileGuideTile: React.FC<IGuideTileProps> = ({
  title,
  text,
  Icon,
  backgroundIcon,
  href,
}) => {
  return (
    <Link href={href} target="_blank" sx={linkSx} underline="none">
      <Grid
        container
        paddingX={1.5}
        paddingY={2}
        height="100%"
        wrap="nowrap"
        alignItems="center"
      >
        <Grid item>
          <Grid
            sx={[iconWrapSx, { background: backgroundIcon }]}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Icon sx={iconSx} />
          </Grid>
        </Grid>
        <Grid item pl={2}>
          <Typography variant="h6" sx={titleSx}>
            {title}
          </Typography>
          <Typography variant="subtitle2" sx={textSx}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

const linkSx = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',

  '&:hover': {
    textDecoration: 'none',
  },
};

const iconWrapSx = {
  borderRadius: '50%',
  width: '40px',
  height: '40px',
};

const iconSx = {
  color: '#fff',
};

const titleSx = {
  marginBottom: 1,
  color: '#2a2a34',
};

const textSx = {
  color: 'grey.600',
};
