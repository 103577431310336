import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  XAxis,
} from 'recharts';
import { MonthChartPreviewLoadingAnimation } from 'components/Dashboard/components/MonthChartPreview/components/MonthChartPreviewLoadingAnimation';
import { IPreviewChartRecord } from 'components/Dashboard/types/chart';

interface ISimpleMonthChartProps {
  data?: IPreviewChartRecord[];
}

export const MonthChartPreview: React.FC<ISimpleMonthChartProps> = ({
  data,
}) => {
  const { palette, typography } = useTheme();

  if (!data) {
    return <MonthChartPreviewLoadingAnimation />;
  }

  if (!data.length) {
    return null;
  }

  const firstLabel = data[0].date;
  const lastLabel = data[data.length - 1].date;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          right: 4,
        }}
      >
        <XAxis
          strokeWidth={1}
          stroke={palette.grey[200]}
          fontSize="0.75rem"
          fontFamily={typography.fontFamily}
          dataKey="date"
          dy={5}
          ticks={[firstLabel, lastLabel]}
          interval="preserveStartEnd"
          tick={(props): ReactElement<SVGElement> => {
            const {
              payload: { value },
            } = props;

            return (
              <Text {...props} fill={palette.grey[600]}>
                {value}
              </Text>
            );
          }}
        />
        <Line
          dataKey="value"
          type="basis"
          strokeWidth={2}
          dot={({
            index,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            dataKey,
            ...rest
          }): ReactElement<SVGElement> => {
            const isLastDot = index === data.length - 1;

            return (
              <circle
                {...rest}
                r={isLastDot ? 1 : 0}
                stroke={palette.primary.main}
                strokeWidth={isLastDot ? 2 : 0}
                fill={palette.primary.main}
              />
            );
          }}
          stroke={palette.primary.main}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
