import { accessControl } from 'stars-access-control';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { useOnboardingState } from 'stars-hooks/lib/hooks/api/useOnboardingState';

export const useIsOnboardingCompleted = (): boolean | undefined => {
  const { userId } = useUserDataContext();
  const { data } = useOnboardingState(Number(userId), {
    shouldFetch: Boolean(userId),
  });

  if (!data) {
    return undefined;
  }

  const {
    site_created,
    spot_created,
    account_deposited,
    campaign_created,
  } = data || {};

  return accessControl.role.isAdvertiser()
    ? Boolean(campaign_created && account_deposited)
    : Boolean(site_created && spot_created);
};
