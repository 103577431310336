import React, { CSSProperties, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IRatingScaleProps {
  rate?: number;
  onChange: (rate: number) => void;
}

export const RatingScale: React.FC<IRatingScaleProps> = ({
  rate,
  onChange,
}) => {
  const [hoverRate, setHoverRate] = useState<number>();

  return (
    <Grid
      item
      container
      flexWrap="nowrap"
      overflow="scroll"
      width="fit-content"
      sx={containerSx}
      onMouseLeave={(): void => setHoverRate(undefined)}
    >
      {[...Array(10).keys()].map((number: number) => (
        <Grid
          key={number}
          item
          sx={getNumberSx(number + 1, hoverRate || rate)}
          onClick={(): void => onChange(number + 1)}
          onMouseEnter={(): void => setHoverRate(number + 1)}
        >
          {number + 1}
        </Grid>
      ))}
    </Grid>
  );
};

const containerSx: SxProps = {
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const getNumberSx =
  (number: number, rate?: number): SxProps<Theme> =>
  ({ breakpoints }) => {
    const isFirst = number === 1;
    const isSelected = rate && number <= rate;
    const isLowRate = (isSelected ? rate : number) < 7;
    const isMidRate = (isSelected ? rate : number) < 9;
    const borderColor: CSSProperties['borderColor'] = isLowRate
      ? 'error.main'
      : isMidRate
      ? 'warning.main'
      : 'success.main';

    return {
      width: 32,
      minWidth: 32,
      height: 32,
      fontSize: 16,
      border: 'solid 1px',
      borderColor,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ml: isFirst ? 0 : 1.25,
      cursor: 'pointer',
      backgroundColor: isSelected ? borderColor : 'white',
      fontWeight: 500,
      color: isSelected ? 'white' : 'text.primary',

      [breakpoints.down(520)]: {
        width: 24,
        minWidth: 24,
        height: 24,
        ml: isFirst ? 0 : 0.5,
        fontSize: 13,
      },
    };
  };
