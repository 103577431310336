import React from 'react';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { DateRangePicker } from 'stars-components/lib/components/DateRangePicker';
import { INonEmptyDateRange } from 'stars-components/lib/components/DateRangePicker';
import { LinearLoadingAnimation } from 'stars-components/lib/components/loadingAnimation/LinearLoadingAnimation';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { CURRENT_DATE_RANGE } from 'components/Dashboard/constants/dates';

interface IDashboardDateRangeProps {
  months?: ReadonlyArray<string>;
  onChange(dateRange: INonEmptyDateRange): void;
  isLoading?: boolean;
}

export const DashboardDateRange: React.FC<
  IDashboardDateRangeProps
> = ({ months, isLoading, onChange }) => {
  const { isMobile } = useScreenSize();
  const DATE_RANGE_WIDTH = isMobile ? '100%' : 240;

  if (isLoading) {
    return (
      <LinearLoadingAnimation
        height={28}
        width={DATE_RANGE_WIDTH}
        rx={2}
        ry={2}
      />
    );
  }

  if (!months?.length) {
    return null;
  }

  const sortedMonths = [...months].sort(
    (month1, month2) => dayjs(month2).unix() - dayjs(month1).unix(),
  );

  const maxDate = dayjs(sortedMonths[0]).endOf('month');
  const minDate = dayjs(
    sortedMonths[sortedMonths.length - 1],
  ).startOf('month');

  return (
    <Box
      sx={{
        width: DATE_RANGE_WIDTH,
      }}
      mb={isMobile ? 0 : 1}
      mt={isMobile ? 0 : '-2px'}
    >
      <DateRangePicker
        initialValue={CURRENT_DATE_RANGE}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Box>
  );
};
