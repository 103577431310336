import { useTranslation } from 'next-i18next';
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { DashboardChart } from 'components/Dashboard/components/DashboardChart';
import { IChartData } from 'components/Dashboard/types/chart';

interface IDashboardOverviewProps {
  toolbarItems: ReadonlyArray<React.ReactNode>;
  amountItems: ReadonlyArray<React.ReactNode>;
  chartData: IChartData;
}

export const DashboardOverview: React.FC<IDashboardOverviewProps> = ({
  toolbarItems,
  amountItems,
  chartData,
}) => {
  const { t } = useTranslation('common');
  const { isMobile } = useScreenSize();

  const toolbarElements = isMobile
    ? toolbarItems.slice(-1)
    : toolbarItems;
  const footerElements = isMobile ? toolbarItems.slice(0, -1) : [];

  return (
    <Paper sx={rootSx}>
      <Box sx={headerSx}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Typography variant="h5" fontWeight={500} mb={1}>
            {t('Overview')}
          </Typography>
          <Grid item>
            <Grid container sx={toolbarElementsSx}>
              {toolbarElements.map((toolbarItem, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid
                  item
                  ml={isMobile ? 0 : 3}
                  mb={isMobile ? 1.5 : 0}
                  container={isMobile}
                  key={index}
                >
                  {toolbarItem}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap">
          {amountItems.map((amountitem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} item mr={4}>
              {amountitem}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={chartWrapSx}>
        <DashboardChart chartData={chartData} />
      </Box>
      {footerElements && (
        <Grid container justifyContent="center" mt={1}>
          {footerElements.map((footerItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid mr={3} item key={index}>
              {footerItem}
            </Grid>
          ))}
        </Grid>
      )}
    </Paper>
  );
};

const rootSx: SxProps<Theme> = ({ shape, breakpoints }) => ({
  paddingTop: 3,
  paddingBottom: 1.5,
  boxSizing: 'border-box',
  boxShadow: shape.boxShadow,
  borderRadius: shape.borderRadiusLarge,

  [breakpoints.down('sm')]: {
    paddingTop: 2.5,
    paddingBottom: 2.5,
  },
});

const headerSx: SxProps<Theme> = ({ breakpoints }) => ({
  paddingRight: 3.5,
  paddingBottom: 2,
  paddingLeft: 3.5,

  [breakpoints.down('md')]: {
    paddingRight: 1.5,
    paddingLeft: 1.5,
  },

  [breakpoints.down('sm')]: {
    paddingBottom: 0.5,
  },
});

const toolbarElementsSx = {
  position: 'relative',
  bottom: '-2px',
};

const chartWrapSx: SxProps<Theme> = ({ breakpoints }) => ({
  paddingRight: 1.5,
  paddingLeft: 1.5,
  height: '380px',

  [breakpoints.down('sm')]: {
    height: '324px',
    paddingRight: 1,
    paddingLeft: 1,
  },
});
