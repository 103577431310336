import { useTranslation } from 'next-i18next';
import React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { IAccountManagerResponseModel } from 'stars-api/lib/types/users/accountManagerResponseModel';
import { AccountManagerStatus } from 'stars-api/lib/types/users/accountManagerStatus';
import { useToggle } from 'stars-hooks/lib/hooks/useToggle';
import { Contacts } from 'components/Dashboard/components/ManagerCard/components/Contacts';
import { ManagerInfoDialog } from 'components/Dashboard/components/ManagerCard/components/ManagerInfoDialog';
import { ManagerMenuButton } from 'components/Dashboard/components/ManagerCard/components/ManagerMenuButton';
import { ManagerName } from 'components/Dashboard/components/ManagerCard/components/ManagerName';
import { ManagerPhoto } from 'components/Dashboard/components/ManagerCard/components/ManagerPhoto';
import { NotOnDuty } from 'components/Dashboard/components/ManagerCard/components/NotOnDuty';
import { RatingButton } from 'components/Dashboard/components/ManagerCard/components/rating/RatingButton';

export const ManagerInfo: React.FC<IAccountManagerResponseModel> = ({
  first_name,
  last_name,
  contacts = {},
  avatar,
  email,
  appointment_link,
  status = { status: AccountManagerStatus.ON_DUTY },
  position,
  description,
  photo,
}) => {
  const { t } = useTranslation('common');
  const { isOpen, toggleOpen, toggleClose } = useToggle();
  const isOnDuty = status.status === AccountManagerStatus.ON_DUTY;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(425));
  const isSmallWidget = useMediaQuery(
    theme.breakpoints.between('sm', 881),
  );
  const isMobileView = isSmallScreen || isSmallWidget;
  const name = `${first_name} ${last_name}`;
  const role = position || t<string>('default_role');

  const openManagerInfoDialog = (): void => {
    description && toggleOpen();
  };

  return (
    <Grid
      container
      sx={containerSx}
      flexDirection="column"
      flexWrap="nowrap"
      justifyContent="center"
    >
      <Grid item mb={1.5}>
        <Typography variant="h6">{t('your_manager_info')}</Typography>
      </Grid>
      <Grid
        item
        container
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Grid
          item
          container
          width="auto"
          flexWrap="nowrap"
          sx={managerInfoSx}
          onClick={openManagerInfoDialog}
        >
          <ManagerPhoto avatar={avatar} />
          <ManagerName
            name={name}
            position={role}
            status={status?.status || AccountManagerStatus.ON_DUTY}
          />
        </Grid>
        <Grid
          item
          container
          width="auto"
          spacing={1}
          flexWrap="nowrap"
          ml={1}
        >
          {isMobileView && appointment_link ? (
            <ManagerMenuButton appointmentLink={appointment_link} />
          ) : (
            <>
              <RatingButton />
              {appointment_link && (
                <Grid item>
                  <IconButton
                    sx={buttonSx}
                    href={appointment_link}
                    target="_blank"
                  >
                    <CalendarMonthIcon color="primary" />
                  </IconButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {isOnDuty ? (
        <Contacts
          email={email}
          skype={contacts.skype}
          telegram={contacts.telegram}
        />
      ) : (
        <NotOnDuty
          statusComment={status.comment}
          email={email}
          skype={contacts.skype}
          telegram={contacts.telegram}
        />
      )}
      <ManagerInfoDialog
        name={name}
        position={role}
        photo={photo}
        description={description}
        isOpen={isOpen}
        onClose={toggleClose}
      />
    </Grid>
  );
};

const containerSx: SxProps = {
  px: 3.5,
  py: 2,
  height: '100%',
};

const buttonSx: SxProps = {
  border: 'solid 1px',
  color: 'grey.400',
  width: 40,
  height: 40,
};

const managerInfoSx: SxProps = {
  cursor: 'pointer',
};
