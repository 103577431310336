import Image from 'next/image';
import React from 'react';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { getCDNMediaFilePath } from 'helpers/getCDNMediaFilePath';

interface IManagerInfoDialogProps {
  name: string;
  position: string;
  photo?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ManagerInfoDialog: React.FC<IManagerInfoDialogProps> = ({
  name,
  position,
  isOpen,
  photo,
  description,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} keepMounted onClose={onClose} sx={dialogSx}>
      <DialogTitle sx={dialogTitleSx}>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h5" fontSize={34}>
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={18} color="grey.600">
              {position}
            </Typography>
          </Grid>
        </Grid>
        <IconButton size="small" onClick={onClose} sx={buttonSx}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={dialogContentSx}>
        {photo && (
          <Box sx={contentSx}>
            <Image
              src={getCDNMediaFilePath(photo)}
              alt="Your manager"
              width={500}
              height={0}
            />
          </Box>
        )}
        <DialogContentText sx={contentTextSx} color="textPrimary">
          {description}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const dialogSx: SxProps = {
  '& .MuiDialog-paper': {
    borderRadius: 2,
    maxWidth: 500,
  },
};

const dialogTitleSx: SxProps = {
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'start',
  pr: 2,
};

const dialogContentSx: SxProps = {
  py: 3.5,
  px: 0,
};

const buttonSx: SxProps = {
  color: 'text.primary',
};

const contentSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',

  '& img': {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    maxHeight: 300,
  },
};

const contentTextSx: SxProps = {
  px: 3,
  py: 4,
  whiteSpace: 'pre-wrap',
};
