import { Dayjs } from 'dayjs';
import {
  IUseTotalStatistics,
  useTotalPublisherStatistics,
} from 'components/Dashboard/components/PublisherAdvancedDashboard/hooks/useTotalPublisherStatistics';

interface IPeriod {
  dateFrom: Dayjs;
  dateTo: Dayjs;
}

interface IUsePublisherStatisticsParams {
  currentPeriod: IPeriod;
  previousPeriod: IPeriod;
}

interface IUsePublisherStatistics extends IUseTotalStatistics {
  turnoverRatio?: number;
  earningsRatio?: number;
  revenueRatio?: number;
  isLoadingRatio?: boolean;
}

export const usePublisherStatistics = ({
  currentPeriod,
  previousPeriod,
}: IUsePublisherStatisticsParams): IUsePublisherStatistics => {
  const curentPeriodStatistics = useTotalPublisherStatistics({
    dateFrom: currentPeriod.dateFrom,
    dateTo: currentPeriod.dateTo,
  });

  const previuosPeriodStatistics = useTotalPublisherStatistics({
    dateFrom: previousPeriod.dateFrom,
    dateTo: previousPeriod.dateTo,
  });

  const turnoverRatio =
    Number(curentPeriodStatistics.turnover) /
      Number(previuosPeriodStatistics.turnover) || undefined;

  const earningsRatio =
    Number(curentPeriodStatistics.earnings) /
      Number(previuosPeriodStatistics.earnings) || undefined;

  const revenueRatio =
    Number(curentPeriodStatistics.revenue) /
      Number(previuosPeriodStatistics.revenue) || undefined;

  return {
    ...curentPeriodStatistics,
    turnoverRatio,
    earningsRatio,
    revenueRatio,
    isLoadingRatio:
      curentPeriodStatistics.isLoading ||
      previuosPeriodStatistics.isLoading,
  };
};
