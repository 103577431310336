import { useTheme } from '@mui/material';

export const useChartColors = (): {
  barColor: string;
  lineColor: string;
} => {
  const { palette } = useTheme();

  return {
    barColor: palette.primary.main,
    lineColor: palette.success.main,
  };
};
