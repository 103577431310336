import { useTranslation } from 'next-i18next';
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { NewsFeedContent } from 'components/NewsFeed/components/NewsFeedContent';
import { NewsFeedLoadingAnimation } from 'components/NewsFeed/components/NewsFeedLoadingAnimation';
import { NewsSlideControls } from 'components/NewsFeed/components/NewsSlideControls';
import { NewsTimeIndicator } from 'components/NewsFeed/components/NewsTimeIndicator';
import { useNewsControls } from 'components/NewsFeed/hooks/useNewsControls';
import { useParsedNews } from 'components/NewsFeed/hooks/useParsedNews';
import {
  activeImgSx,
  blockTitleSx,
  imgContainerSx,
  imgSx,
  newsTitleSx,
  rootSx,
} from 'components/NewsFeed/styles';

interface INewsFeedProps {
  isLoading?: boolean;
}

export const NewsFeed: React.FC<INewsFeedProps> = ({ isLoading }) => {
  const news = useParsedNews();
  const { t } = useTranslation('common');
  const {
    activeNewsIndex,
    indicatorWidthPercent,
    newsChangeHandler,
    indicatorPlayHandler,
    indicatorPauseHandler,
    prevNewsClickHandler,
    nextNewsClickHandler,
  } = useNewsControls(news.length);

  const activeNews = news[activeNewsIndex];

  const shouldShowControls = news.length > 1;

  if (isLoading) {
    return <NewsFeedLoadingAnimation />;
  }

  return (
    <Paper
      elevation={2}
      sx={rootSx}
      onMouseEnter={indicatorPauseHandler}
      onMouseLeave={indicatorPlayHandler}
    >
      <div>
        <Typography variant="h5" fontWeight={500} sx={blockTitleSx}>
          {t('News feed')}
        </Typography>
        <Typography
          variant="h4"
          sx={newsTitleSx}
          data-test="newsFeedTitle"
        >
          {activeNews?.title}
        </Typography>
        {activeNews && <NewsFeedContent news={activeNews} />}
      </div>
      <div>
        <Grid
          container
          mt={4}
          mb={4}
          justifyContent="center"
          wrap="nowrap"
        >
          {shouldShowControls &&
            news.map((_, index) => (
              <NewsTimeIndicator
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                activeNewsIndex={activeNewsIndex}
                indicatorWidthPercent={indicatorWidthPercent}
                indicatorClickHandler={newsChangeHandler}
              />
            ))}
        </Grid>
        <Box sx={imgContainerSx}>
          {news.map((newsItem, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={[
                imgSx,
                index === activeNewsIndex && activeImgSx,
                { backgroundImage: `url(${newsItem.imageSrc})` },
              ]}
            />
          ))}
          {shouldShowControls && (
            <NewsSlideControls
              onPrevClick={prevNewsClickHandler}
              onNextClick={nextNewsClickHandler}
            />
          )}
        </Box>
      </div>
    </Paper>
  );
};
