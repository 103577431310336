import { useEffect, useState } from 'react';
import {
  INDICATOR_INTERVAL_IN_MS,
  NEWS_CHANGE_TIME_INTERVAL_IN_MS,
} from 'components/NewsFeed/constants';

let timeIntervalId: ReturnType<typeof setTimeout>;

interface IUseNewsTimeIntervalUpdate {
  activeNewsIndex: number;
  indicatorWidthPercent: string;
  newsChangeHandler(index: number): void;
  indicatorPauseHandler(): void;
  indicatorPlayHandler(): void;
  prevNewsClickHandler(): void;
  nextNewsClickHandler(): void;
}

export const useNewsControls = (
  newsCount: number,
): IUseNewsTimeIntervalUpdate => {
  const [isPaused, setPaused] = useState(false);
  const [activeNewsIndex, setActiveNewsIndex] = useState(0);
  const [passedTimeInMs, setPassedTimeInMs] = useState(0);

  const firstNewsIndex = 0;
  const lastNewsIndex = newsCount - 1;
  const isFirstNews = activeNewsIndex === firstNewsIndex;
  const isLastNews = activeNewsIndex === lastNewsIndex;

  const newsChangeHandler = (index: number): void => {
    clearInterval(timeIntervalId);
    setActiveNewsIndex(index);
    setPassedTimeInMs(0);
  };

  const indicatorPauseHandler = (): void => setPaused(true);

  const indicatorPlayHandler = (): void => setPaused(false);

  const prevNewsClickHandler = (): void => {
    if (isFirstNews) {
      newsChangeHandler(lastNewsIndex);
    } else {
      newsChangeHandler(activeNewsIndex - 1);
    }
  };

  const nextNewsClickHandler = (): void => {
    if (isLastNews) {
      newsChangeHandler(firstNewsIndex);
    } else {
      newsChangeHandler(activeNewsIndex + 1);
    }
  };

  useEffect(() => {
    if (isPaused) {
      return clearInterval(timeIntervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeIntervalId = setInterval(() => {
      setPassedTimeInMs(
        (prevTime) => prevTime + INDICATOR_INTERVAL_IN_MS,
      );
    }, INDICATOR_INTERVAL_IN_MS);

    return (): void => {
      clearInterval(timeIntervalId);
    };
  }, [isPaused]);

  useEffect(() => {
    if (passedTimeInMs >= NEWS_CHANGE_TIME_INTERVAL_IN_MS) {
      setActiveNewsIndex((prevActiveNewsIndex) => {
        const prevNewsIsLast = prevActiveNewsIndex === newsCount - 1;

        if (prevNewsIsLast) {
          return 0;
        }

        return prevActiveNewsIndex + 1;
      });
      setPassedTimeInMs(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedTimeInMs]);

  const indicatorWidthPercent = `${
    ((passedTimeInMs + INDICATOR_INTERVAL_IN_MS) /
      NEWS_CHANGE_TIME_INTERVAL_IN_MS) *
    100
  }%`;

  return {
    activeNewsIndex,
    indicatorWidthPercent,
    newsChangeHandler,
    indicatorPlayHandler,
    indicatorPauseHandler,
    prevNewsClickHandler,
    nextNewsClickHandler,
  };
};
