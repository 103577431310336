import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import { useToggle } from 'stars-hooks/lib/hooks/useToggle';
import { RatingDialog } from 'components/Dashboard/components/ManagerCard/components/rating/RatingDialog';
import { RatingIcon } from 'components/Dashboard/components/ManagerCard/components/rating/RatingIcon';

export const RatingButton: React.FC = () => {
  const { isOpen, toggleOpen, toggleClose } = useToggle();

  return (
    <Grid item>
      <IconButton sx={buttonSx} onClick={toggleOpen}>
        <RatingIcon />
      </IconButton>
      <RatingDialog isOpen={isOpen} onClose={toggleClose} />
    </Grid>
  );
};

const buttonSx: SxProps = {
  border: 'solid 1px',
  color: 'grey.400',
  width: 40,
  height: 40,
};
