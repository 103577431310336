import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { SxProps, SystemStyleObject } from '@mui/system';
import { GuideTile } from 'components/GuideTile';
import { IGuideTileProps } from 'types/guideTiles';

interface IGuideTilesProps {
  guideTileList: ReadonlyArray<IGuideTileProps>;
  direction?: 'column' | 'row';
}

export const GuideTiles: React.FC<IGuideTilesProps> = ({
  guideTileList,
  direction = 'column',
}) => {
  const isRow = direction === 'row';
  const countGuideTile = guideTileList.length;

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      flexGrow={1}
      height="calc(100% + 16px)"
    >
      {guideTileList.map((guideTile, index) => (
        <Grid
          item
          data-test={`guideTile_${index}`}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          sx={({ breakpoints }): SystemStyleObject => ({
            minHeight: 136,
            width: isRow ? `${(100 / countGuideTile) * 2}%` : '100%',

            [breakpoints.down('lg')]: {
              width: '100%',
            },
          })}
        >
          <Paper sx={paperSx} elevation={2}>
            <GuideTile {...guideTile} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

const paperSx: SxProps<Theme> = ({ shape }) => ({
  height: '100%',
  borderRadius: shape.borderRadiusLarge,
  boxShadow: shape.boxShadow,
  display: 'flex',
  alignItems: 'center',
});
