import { Dayjs } from 'dayjs';

export const getDaysRange = (
  startDate: Dayjs,
  endDate: Dayjs,
): Date[] => {
  const result: Date[] = [];
  const start = startDate.toDate();
  const end = endDate.toDate();

  for (
    const date = start;
    date <= end;
    date.setDate(date.getDate() + 1)
  ) {
    result.push(new Date(date));
  }

  return result;
};
