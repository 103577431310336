import { useTranslation } from 'next-i18next';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import TrackChangesSharpIcon from '@mui/icons-material/TrackChangesSharp';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useTheme } from '@mui/material';
import { useScreenSize } from 'stars-components/lib/hooks/useScreenSize';
import { IGuideTileProps } from 'types/guideTiles';

export const useAdvertiserGuideTileList = (
  isRow?: boolean,
): ReadonlyArray<IGuideTileProps> => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();
  const { isTablet, isDesktop, isLargeDesktop } = useScreenSize();

  const tiles: ReadonlyArray<IGuideTileProps> = [
    {
      title: t('How can I create a campaign'),
      text: t('Step-by-step guide'),
      Icon: SendSharpIcon,
      backgroundIcon: '#ff9e18',
      href: 'https://support.trafficstars.com/en/articles/1840593-how-can-i-create-a-campaign',
    },
    {
      title: t('How to set up tracking conversions'),
      text: t('S2S postback tracking'),
      backgroundIcon: palette.success.main,
      Icon: TrackChangesSharpIcon,
      href: 'https://support.trafficstars.com/en/articles/6025385-how-to-set-up-tracking-conversions',
    },
  ];

  if (isRow && (isTablet || (isDesktop && !isLargeDesktop))) {
    return tiles;
  }

  return [
    ...tiles,
    {
      title: t('Insights for the spotless start'),
      text: t('How to do everything right'),
      backgroundIcon: '#7d62f4',
      Icon: LanguageSharpIcon,
      href: 'https://support.trafficstars.com/en/articles/4635550-insights-for-the-spotless-start',
    },
    {
      title: t('Do you have any tips to optimise my campaigns'),
      text: t('Campaign optimisation technique'),
      backgroundIcon: '#ff1547',
      Icon: TrendingUpIcon,
      href: 'https://support.trafficstars.com/en/articles/1840528-do-you-have-any-tips-to-optimise-my-campaigns',
    },
  ];
};
