import { formatCurrency } from 'stars-utils/lib/helpers/money/formatCurrency';
import { getShortNumber } from 'stars-utils/lib/helpers/numbers/getShortNumber';
import { isMetricCurrency } from 'components/Dashboard/helpers/isMetricCurrency';
import { Metric } from 'components/Dashboard/types/metric';

export const formatValueByMetric = (
  value: number,
  metric: Metric,
): string => {
  if (isMetricCurrency(metric)) {
    return `${formatCurrency(value)} USD`;
  }

  return getShortNumber(value);
};
