import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import {
  nextSlideBtnSx,
  prevSlideBtnSx,
  slideBtnSx,
} from 'components/NewsFeed/styles';

interface INewsSlideControlsProps {
  onPrevClick(): void;
  onNextClick(): void;
}

export const NewsSlideControls: React.FC<INewsSlideControlsProps> = ({
  onPrevClick,
  onNextClick,
}) => {
  return (
    <>
      <Button
        variant="text"
        onClick={onPrevClick}
        sx={[slideBtnSx, prevSlideBtnSx]}
      >
        <ChevronLeftIcon color="primary" />
      </Button>
      <Button
        variant="text"
        onClick={onNextClick}
        sx={[slideBtnSx, nextSlideBtnSx]}
      >
        <ChevronRightIcon color="primary" />
      </Button>
    </>
  );
};
