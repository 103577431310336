import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';

/**
 * Возвращает userId, кроме случая, когда пользователь superuser
 * Иначе вернет undefined, т. к. userId не нужен для некоторых запросов данных дашборда при таком условии.
 */
export const useDashboardUserId = (): number | undefined => {
  const { userId, isSuperUser } = useUserDataContext();

  if (isSuperUser) {
    return undefined;
  }

  return userId;
};
